import { Injectable, isDevMode } from "@angular/core";
import { Observable } from "rxjs";
import { HttpServiceAbstract } from "./http.abstract.service";
import { HttpClient } from "@angular/common/http";
import { LoggerService } from "./logger.service";

import { environment } from "../../../environments/environment";
import { JWTService } from "./jwt.service";

@Injectable({
  providedIn: "root",
})
export class HttpService extends HttpServiceAbstract {
  public constructor(http: HttpClient, loggerService: LoggerService, jwtService: JWTService) {
    super(http, loggerService, jwtService);
  }

  public get(req: { url: string; params?: Params; expires?: number; force?: boolean; uri?: string }): Observable<any> {
    if (req.params && req.params.cache_bust) {
      req.params.cache_bust = this._cacheBustId();
    }

    if (!isDevMode()) {
      if (!req.params) {
        req.params = {};
      }
      req.params.cache_bust = this._cacheBustId();
    }

    if (req.force) {
      if (!req.params) {
        req.params = {};
      }
      req.params.cache_bust = this._cacheBustId();
    }
    req.url = environment.REST_LEGACY_URL + req.url;
    return this.send(req.url, { method: "GET", params: req.params }, req.expires, req.force);
  }
  public getLegacy(req: { url: any; params?: Params; expires?: number; force?: boolean; uri?: string }): Observable<any> {
    req.url = `${environment.REST_LEGACY_URL}/v1/${req.url}`;
    return this.send(req.url, { method: "GET", params: req.params }, req.expires, req.force);
  }

  public head(url: string): Observable<any> {
    return this.send(url, { method: "HEAD" });
  }

  public options(url: string): Observable<any> {
    url = environment.REST_LEGACY_URL + url;
    return this.send(url, { method: "OPTIONS" });
  }
  public optionsLegacy(url: string): Observable<any> {
    url = `${environment.REST_LEGACY_URL}/v1/${url}`;
    return this.send(url, { method: "OPTIONS" });
  }

  public delete(url: string, body: any): Observable<any> {
    url = environment.REST_LEGACY_URL + url;
    return this.send(url, { params: body, method: "DELETE" });
  }
  public deleteLegacy(url: string, body: any): Observable<any> {
    url = `${environment.REST_LEGACY_URL}/v1/${url}`;
    return this.send(url, { body, method: "DELETE" });
  }

  public post(url: string, body: any): Observable<any> {
    url = environment.REST_LEGACY_URL + url;
    return this.send(url, { body, method: "POST" });
  }

  // Used for GraphQL calls
  public query<T>(query: string, options: any = {}): Observable<T | any> {
    return this.send<T>(environment.GRAPH_URL, { body: { query }, method: "POST", ...options });
  }

  public postLegacy(url: string, body: any): Observable<any> {
    url = `${environment.REST_LEGACY_URL}/v1/${url}`;
    return this.send(url, { body, method: "POST" });
  }

  public put(url: string, body: any): Observable<any> {
    return this.send(url, { body, method: "PUT" });
  }

  public patch(url: string, body: any): Observable<any> {
    return this.send(url, { body, method: "PATCH" });
  }

  private _cacheBustId() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}

/**
 * Define type for valid parameters
 */
export type Params = string | URLSearchParams | any | { [key: string]: any | any[] };
