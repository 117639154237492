import { Injectable } from "@angular/core";
import { Observable, lastValueFrom, map } from "rxjs";
import { HttpService } from "./http.service";
import { JWTService } from "./jwt.service";
import { LocationService } from "./location.service";
import Bugsnag from "@bugsnag/js";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(private _httpService: HttpService, private _jwtService: JWTService, private _locationService: LocationService) {
    this._jwtService.onSessionIdChanged.subscribe(() => {
      this._setSessionId();
    });
  }

  public async init(): Promise<void> {
    try {
      const jwt = await lastValueFrom(this._getSession());

      if (!jwt) return;

      const existingJWT = this._jwtService.getJWTString();

      if (!!existingJWT) {
        if (existingJWT !== jwt) Bugsnag.notify(new Error("JWT stored in local storage does not match the one returned from the server"));

        return;
      }

      // TODO: once we're happy that this is working (i.e. no Bugsnag errors are being reported) we can set the token in memory and
      //       prevent it being stored the local storage
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  private _getSession(): Observable<string | null> {
    return this._httpService
      .send(`/sessions`, {
        method: "GET",
      })
      .pipe(map((response: { jwt: string } | null) => response?.jwt || null));
  }

  private _setSession(): Observable<string | any[] | null> {
    return this._httpService.send(`/sessions`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this._jwtService.getJWTString()}`,
      },
    });
  }

  private _setSessionId(): void {
    try {
      this._setSession().subscribe();
    } catch (error) {
      Bugsnag.notify(error);
    }
  }
}
