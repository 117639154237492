import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { Observable, Subject, fromEventPattern, map, merge, takeUntil } from "rxjs";

type T_WindowOpenTarget = "_self" | "_blank" | "_parent" | "_top" | string;

@Injectable({
  providedIn: "root",
})
export class WindowService implements OnDestroy {
  private _renderer: Renderer2;
  private _onFocus: Observable<boolean>;
  private _destroy = new Subject<void>();

  public get onWindowFocus(): Observable<boolean> {
    if (!this._onFocus) {
      this._createOnFocusObservable();
    }

    return this._onFocus;
  }

  constructor(private _rendererFactory2: RendererFactory2) {
    this._renderer = this._rendererFactory2.createRenderer(null, null);
  }

  public get navigator(): Navigator {
    return window.navigator;
  }

  public matchMedia(query: string): undefined | MediaQueryList {
    if (!window.matchMedia) return undefined;

    return window.matchMedia(query);
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  public open(url: string, target: T_WindowOpenTarget = "_self", options?: string): void {
    window.open(url, target, options);
  }

  private _createOnFocusObservable() {
    let removeFocusEventListener: () => void;
    let removeBlurEventListener: () => void;
    const createFocusEventListener = (handler: (e: Event) => boolean | void) => {
      removeFocusEventListener = this._renderer.listen("window", "focus", handler);
    };
    const createBlurEventListener = (handler: (e: Event) => boolean | void) => {
      removeBlurEventListener = this._renderer.listen("window", "blur", handler);
    };

    // Create an observable that emits true when the window gains focus and false when it loses focus
    this._onFocus = merge(
      fromEventPattern<Event>(createFocusEventListener, () => {
        removeFocusEventListener();
      }),
      fromEventPattern<Event>(createBlurEventListener, () => {
        removeBlurEventListener();
      })
    ).pipe(
      // Stop listening when the service is destroyed
      takeUntil(this._destroy),
      // Map the event to true for focus and false for blur
      map((event) => {
        return event.type === "focus";
      })
    );
  }
}
