import { Injectable, OnDestroy } from "@angular/core";
import { SubSink } from "subsink";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { LoginV4 } from "@apis/_core/types/login-v4.interface";
import { GAService } from "./ga.service";
import { HttpService } from "./http.service";
import { JWTService } from "./jwt.service";
import { NavigationService } from "./navigation.service";
import { Observable, of, Subject, Subscription, throwError, timer } from "rxjs";
import { filter, tap, catchError, switchMap, map } from "rxjs/operators";
import { FeatureFlagsService } from "./feature-flags.service";
import Bugsnag from "@bugsnag/js";
import { CacheService } from "./cache.service";
import { RecaptchaService } from "./recaptcha.service";
import { PatientsService } from "./patients.service";
import { E_CommsMethod } from "@apis/_core/enums/comms-method";
import { Constants } from "src/constants";

export type T_SpecificContactMethod = "email" | "mobile" | null;

@Injectable({
  providedIn: "root",
})
export class LoginV4Service implements OnDestroy {
  private _deleteRedirectPath: Subscription;
  private _subs = new SubSink();
  public onLogin: BehaviorSubject<any> = new BehaviorSubject([]);

  public onPasswordProgress = new Subject<boolean>();
  public onPatientSearch: BehaviorSubject<LoginV4.Public.Search.PostHTTPResponse | null> = new BehaviorSubject({});
  public onVerificationCheck: BehaviorSubject<LoginV4.Public.Verification.Check.PostHTTPResponse | null> = new BehaviorSubject(null);
  public onVerificationRequest: BehaviorSubject<LoginV4.Public.Verification.Comms.PostHTTPResponse | null> = new BehaviorSubject(null);
  public onEmailVerificationProgress: BehaviorSubject<LoginV4.Public.Verification.EmailVerification.Progress | null> = new BehaviorSubject(null);

  public onPasswordAuthRequest: BehaviorSubject<LoginV4.Public.Authentication.PostHTTPResponse | null> = new BehaviorSubject(null);

  public loginData: LoginV4.Public.Authentication.PostHTTPResponse = {};

  private _newUserPasswordCreated: LoginV4.Public.Authentication.PostHTTPRequest = {
    password: null,
  };

  public patientSearchData: LoginV4.Public.Search.PostHTTPRequest = {};
  public ObfusicatedPatientData: LoginV4.Public.Search.PostHTTPResponse["patient_data"] = {};
  public account_locked_time_remaining: number | undefined;
  public has_redirect: string | undefined;
  private _returnPath: string | null = null;

  constructor(
    private _navigationService: NavigationService,
    private _httpService: HttpService,
    private _jwtService: JWTService,
    private _gaService: GAService,
    private _featureFlags: FeatureFlagsService,
    private _cacheService: CacheService,
    private _recaptchaService: RecaptchaService,
    private _patientsService: PatientsService
  ) {}

  public get redirectPath(): string {
    return this._cacheService.get(Constants.LOGIN_REDIRECT_PATH_STORAGE_KEY) || "";
  }

  public set redirectPath(redirectPath: string) {
    if (redirectPath) this._cacheService.set(Constants.LOGIN_REDIRECT_PATH_STORAGE_KEY, redirectPath);
  }

  public get returnPath(): string | null {
    const returnPath = this._returnPath || "/";

    this._returnPath = null;

    return returnPath;
  }

  public set returnPath(returnPath: string | null) {
    this._returnPath = returnPath;
  }

  public goToLogin(redirectPath?: string | null, path?: string): void {
    this.redirectPath = redirectPath || "/my-dental";
    this._navigationService.navigate(path ? `login/${path}` : "login");
  }

  public loginRedirect(): void {
    if (this.redirectPath && !["undefined", "null"].includes(this.redirectPath)) {
      window.location.href = this.redirectPath;
    } else {
      window.location.href = "/my-dental";
    }

    if (this._deleteRedirectPath) this._deleteRedirectPath.unsubscribe();

    // Delete the redirect path from the cache after a slight delay to allow
    // for multiple calls to loginRedirect depending on the login flow
    this._deleteRedirectPath = timer(1000).subscribe(() => {
      this._cacheService.delete(Constants.LOGIN_REDIRECT_PATH_STORAGE_KEY);
    });
  }

  activateRoute(route?: string, state?: { [key: string]: any }) {
    const prefix = `login`;
    const is_restricted = this._jwtService.isRestricted ? "restricted/" : "";

    if (!route) {
      this.onPatientSearch.next(null);
      this._navigationService.navigate(`${is_restricted}${prefix}`);
    } else {
      this._navigationService.navigate(`${is_restricted}login/${route}`, {
        skipLocationChange: true,
        state,
      });
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  isLoggedIn() {
    return this._jwtService.getJWT("access_level") === "PATIENT";
  }

  set SavedPassword(pass: LoginV4.Public.Authentication.PostHTTPRequest) {
    if (pass) {
      this._newUserPasswordCreated = pass;
    }
  }

  get SavedPassword(): LoginV4.Public.Authentication.PostHTTPRequest {
    return this._newUserPasswordCreated;
  }

  public async patientSearch(payload: LoginV4.Public.Search.PostHTTPRequest) {
    try {
      payload.recaptcha_token = await this._recaptchaService.getRecaptchaToken("login");
    } catch {}

    this.patientSearchData = { ...payload };
    this.onVerificationRequest.next(null);
    this._subs.sink = this._httpService.post(`/verification/v1/search`, this.patientSearchData).subscribe(
      (response: LoginV4.Public.Search.PostHTTPFullResponse) => {
        if (response.data?.error && !response.data?.request_body) {
          this.onPatientSearch.next({
            status_code: response.data?.status_code,
            error: response.data.error,
          });
          return;
        }

        const { data } = response;

        if (data?.practice_blocked) {
          this._navigationService.navigate("/restricted/access");
          return;
        }
        this.ObfusicatedPatientData = data?.patient_data;

        this.onPatientSearch.next({ ...data });

        if (data?.account_locked) {
          this.account_locked_time_remaining = data.locked_time_remaining;
        }
      },
      (err: LoginV4.Public.Search.PostHTTPFullResponse) => {
        const status_code = err?.data?.status_code;
        this._gaService.error(`login_v4_patient_search_${status_code}`);
        this.account_locked_time_remaining = err?.data?.error?.locked_time_remaining;
        this.ObfusicatedPatientData = err?.data?.error?.patient_data;
        if (status_code === LoginV4.Public.Search.StatusCodes.UNABLE_TO_VERIFY_IDENTITY) {
          this.activateRoute("error-verify-identity");
          return;
        }
        this.onPatientSearch.next({
          status_code,
          ...err?.data?.error,
        });
      }
    );
  }

  public handlePatientSearch(specificContactMethod: T_SpecificContactMethod): Observable<boolean> {
    return this.onPatientSearch.asObservable().pipe(
      filter((search_payload) => {
        return !!search_payload?.status_code;
      }),
      switchMap((search_payload) => {
        if (!search_payload) return of<boolean>(false);

        const { status_code } = search_payload;
        const { MULTIPLE_PATIENT_RECORDS_FOUND, PATIENT_PORTAL_ACCOUNT_NOT_FOUND, PATIENT_RECORD_NOT_FOUND, PATIENT_PORTAL_ACCOUNT_FOUND, ACCOUNT_LOCKED } =
          LoginV4.Public.Search.StatusCodes;

        switch (status_code) {
          case MULTIPLE_PATIENT_RECORDS_FOUND:
          case PATIENT_RECORD_NOT_FOUND:
            this.activateRoute("find-record");
            break;
          case PATIENT_PORTAL_ACCOUNT_NOT_FOUND:
            if (specificContactMethod === "mobile") {
              this.activateRoute("verify");
              this.verificationRequest({
                type: "mobile_phone",
              });
            } else {
              if (!search_payload.patient_data?.email_address) {
                this.activateRoute("one-last-thing");
                return of<boolean>(false);
              }
              this.activateRoute("confirm-details");
            }
            break;
          case PATIENT_PORTAL_ACCOUNT_FOUND:
            this.verificationRequest({
              type: "mobile_phone",
            });
            break;
          case ACCOUNT_LOCKED:
            this.activateRoute("account-locked");
            break;
          default:
            return of<boolean>(false);
        }

        return of<boolean>(true);
      })
    );
  }

  verificationCheck(payload: LoginV4.Public.Verification.Check.PostHTTPRequest) {
    this.onVerificationCheck.next({
      status_code: LoginV4.Public.Verification.Check.StatusCodes.LOADING,
    });
    this._subs.sink = this._httpService.post(`/verification/v1/check`, payload).subscribe(
      (response: LoginV4.Public.Verification.Check.PostHTTPFullResponse) => {
        const { data } = response;
        this._gaService.goal(`login_v4_verification_check_verifying`);
        this.onVerificationCheck.next({ ...data });
      },
      (err: LoginV4.Public.Verification.Check.PostHTTPFullResponse) => {
        const status_code = err?.data?.status_code || LoginV4.Public.Verification.Check.StatusCodes.ERROR_INVALID_REQUEST;
        this._gaService.error(`login_v4_verification_check_${status_code}`);
        this.onVerificationCheck.next({
          status_code,
          ...err?.data?.error,
        });
      }
    );
  }

  verificationRequest(payload: LoginV4.Public.Verification.Comms.PostHTTPRequest, shouldRedirect = true): Promise<void> {
    this.onVerificationRequest.next(null);

    return new Promise((resolve) => {
      this._subs.sink = this._httpService.post(`/verification/v1/request`, payload).subscribe(
        (resp: LoginV4.Public.Verification.Comms.PostHTTPFullResponse) => {
          this._gaService.goal(`login_v4_verification_comms_${resp.data?.code_sent_via?.field}`);
          this.onVerificationRequest.next({
            status_code: resp?.data?.status_code,
            code_sent_via: resp?.data?.code_sent_via,
          });

          if (shouldRedirect) {
            if (this.hasAccount) {
              this.activateRoute("verify");
            } else {
              this.activateRoute("secure-account");
            }
          }

          resolve();
        },
        (err: LoginV4.Public.Verification.Comms.PostHTTPFullResponse) => {
          const status_code = err?.data?.status_code || LoginV4.Public.Verification.Comms.StatusCodes.ERROR_INVALID_REQUEST;
          this._gaService.error(`login_v4_verification_comms_${status_code}`);
          this.account_locked_time_remaining = err?.data?.locked_time_remaining;
          this.onVerificationRequest.next({
            status_code,
            ...err?.data?.error,
          });

          resolve();
        }
      );
    });
  }

  // Same endpoint used for creating and logging in users
  onPasswordAuthService(payload: LoginV4.Public.Authentication.PostHTTPRequest) {
    const { SUCCESS } = LoginV4.Public.Authentication.StatusCodes;
    this._subs.sink = this._httpService.post(`/verification/v1/authorize`, payload).subscribe(
      (resp: LoginV4.Public.Authentication.PostHTTPFullResponse) => {
        if (resp?.data?.status_code === SUCCESS) {
          this._authenticateUser(resp.data);
        }

        let has_account_suffix = "without_account";
        if (this.onPatientSearch?.value?.status_code === LoginV4.Public.Search.StatusCodes.PATIENT_PORTAL_ACCOUNT_FOUND) {
          has_account_suffix = "with_account";
        }

        this._gaService.goal(`login_v4_authentication_logged_in_${has_account_suffix}`);
        this.has_redirect = resp?.data?.redirect_domain;
        this.onPasswordAuthRequest.next({
          status_code: resp?.data?.status_code,
        });
      },
      (err: LoginV4.Public.Authentication.PostHTTPFullResponse) => {
        const status_code = err?.data?.status_code || LoginV4.Public.Authentication.StatusCodes.ERROR_INVALID_REQUEST;
        this._gaService.error(`login_v4_authentication_${status_code}`);
        this.onPasswordAuthRequest.next({
          status_code,
          ...err?.data?.error,
        });
      }
    );
  }

  /**
   * Gets patient details using the magic link GUID
   *
   * @param guid Magic link GUID
   */
  public getPatientDetailsUsingMagicLink(guid: string): Observable<LoginV4.Public.Verification.Details.GetHTTPResponse> {
    const { SUCCESS, ERROR_INVALID_REQUEST } = LoginV4.Public.Verification.Details.StatusCodes;

    return this._httpService
      .get({
        url: `/verification/v1/details/${guid}`,
      })
      .pipe(
        catchError(() => throwError(ERROR_INVALID_REQUEST)),
        switchMap((response: LoginV4.Public.Verification.Details.GetHTTPFullResponse) => {
          if (response?.data?.status_code === SUCCESS) {
            return of(response.data);
          }

          return throwError(response?.data?.status_code);
        }),
        tap({
          next: (response: LoginV4.Public.Verification.Details.GetHTTPResponse) => {
            if (response.patient) this.patientSearchData = response.patient;
            if (response.feature_flags) this._featureFlags.setFeatureFlags(response.feature_flags);
            if (response.token) this._jwtService.setToken(response.token);
          },
        })
      );
  }

  public storeTemporaryPassword(password: string): void {
    const { SET_PASSWORD_FAILED } = LoginV4.Public.Verification.EmailVerification.Progress;
    this.onPasswordProgress.next(true);
    this._subs.sink = this._httpService.post("/verification/v1/password/store", { password }).subscribe(
      () => {
        /*
          Keep the email address as null if its a password reset so we dont expose the email address to a possible attacker
          The backend will handle obtaining the email address in this instance
        */
        const email_address = this.hasAccount ? null : this.patientSearchData.email_address;
        this.magicLinkRequest(false, email_address);
      },
      () => {
        this.onEmailVerificationProgress.next(SET_PASSWORD_FAILED);
      }
    );
  }

  public magicLinkRequest(resend = false, email: string | null = null): void {
    this._magicLinkRequest(resend, email);
  }

  private _magicLinkRequest(resend: boolean, email: string | null = null): void {
    const { MAGIC_LINK_SENDING, MAGIC_LINK_SENT, MAGIC_LINK_SEND_FAILED } = LoginV4.Public.Verification.EmailVerification.Progress;

    this.onEmailVerificationProgress.next(MAGIC_LINK_SENDING);

    this._httpService
      .post("/verification/v1/magic-link/request", {
        reset: this.hasAccount,
        resend,
        redirect: this.redirectPath,
        email,
      })
      .subscribe(
        () => {
          this.onEmailVerificationProgress.next(MAGIC_LINK_SENT);
        },
        () => {
          this.onEmailVerificationProgress.next(MAGIC_LINK_SEND_FAILED);
        }
      );
  }

  public verifyPassword(password: string): void {
    const { PASSWORD_VERIFIED, PASSWORD_MISMATCH, PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS } = LoginV4.Public.Verification.EmailVerification.Progress;
    const { SUCCESS, PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS: PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS_STATUS_CODE } =
      LoginV4.Public.Verification.PasswordVerify.StatusCodes;
    this.onPasswordProgress.next(true);
    this._httpService.post("/verification/v1/password/verify", { password }).subscribe(
      (response) => {
        if (response.data.status_code === SUCCESS) {
          this.onEmailVerificationProgress.next(PASSWORD_VERIFIED);
        } else if (response.data.status_code === PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS_STATUS_CODE) {
          this.onEmailVerificationProgress.next(PORTAL_VERIFY_PASSWORD_MAX_ATTEMPTS);
        } else {
          this.onEmailVerificationProgress.next(PASSWORD_MISMATCH);
        }

        this.SavedPassword = { password };
      },
      () => {
        this.onEmailVerificationProgress.next(PASSWORD_MISMATCH);
      }
    );
  }

  private _authenticateUser(data: LoginV4.Public.Authentication.PostHTTPResponse) {
    if (data.token) this._jwtService.setToken(data.token);

    const jwt = this._jwtService.getJWT();

    // Allow users to be track in Bugsnag. This will make debugging errors easier if we can look at patient's appointments etc
    Bugsnag.setUser(`${jwt.practice_id}_${jwt.patient_id}`);

    if (data.redirect_domain && data.uid) {
      const path = data.redirect_is_old_world ? "patient-login" : "login";
      let redirectPath = "/my-dental";

      if (this.redirectPath && !["undefined", "null"].includes(this.redirectPath)) {
        ({ redirectPath } = this);
      }

      window.location.href = `${data.redirect_domain}/${path}/redirect?uid=${data.uid}&path=${redirectPath}`;
      return;
    }
  }

  public createLoginFlow(): Observable<{ id: string } | null> {
    return this._httpService.query("mutation { createPatientLoginFlow { id } }").pipe(map((response) => response?.data?.createPatientLoginFlow));
  }

  public forgottenPassword(force = false) {
    if (this._jwtService.isPatientUnauthenticated()) {
      // Temporary solution to fix issue with password reset for unauthenticated patients
      // This will force the patient to go through the login flow again
      this._jwtService.delete();

      window.open("/login", "_self");

      return;
    }

    if (!this.ObfusicatedPatientData?.email_address && !force) {
      this.activateRoute("error-verify-identity");
      return;
    }
    this.activateRoute("reset-pass");
  }

  public redirectUnauthenticatedPatientToLogin(redirectPath = "/my-dental", returnPath?: string): Observable<{ id: string } | null> {
    return this.createLoginFlow().pipe(
      tap((loginFlow) => {
        const { comms_method, is_verified = false } = this._jwtService.getJWT();
        let path: string;

        if (!loginFlow) {
          // If the login flow can't be created then there is a problem with the unauthenticated JWT and we should redirect
          // to the login page and ask them to enter their email address or mobile
          path = comms_method === E_CommsMethod.EMAIL ? "enter-mobile" : "enter-email";
        } else {
          if (this._patientsService?.patientInfo?.registered_portal_user && is_verified) {
            path = "enter-pass";
          } else {
            path = "secure-account";
          }
        }

        this.returnPath = returnPath || redirectPath;

        this.goToLogin(redirectPath, path);
      })
    );
  }

  public get hasAccount(): boolean {
    if (this._patientsService.patientInfo?.registered_portal_user || this.onPatientSearch?.value?.has_account) return true;

    return false;
  }
}
