import { parsePhoneNumberWithError, CountryCode, isValidNumberForRegion } from "libphonenumber-js";
import { BookableAppointmentBase } from "../backend/src/graph/bookable_appointments/bookable-appointment-base";
import { PREVIEW_SITE_APPOINTMENT_MANAGE_TO_PATIENT, PREVIEW_SITE_APPOINTMENT_PATIENT_TO_MANAGE } from "./constants";
import { nanoid, customAlphabet } from "nanoid";
import { LambdaRequest } from "../backend/src/apis/_core/types/LambdaRequest";

export interface I_ApptPreviewMsg {
  id: typeof PREVIEW_SITE_APPOINTMENT_MANAGE_TO_PATIENT | typeof PREVIEW_SITE_APPOINTMENT_PATIENT_TO_MANAGE;
  appointment: BookableAppointmentBase;
  brand_colour: string | null;
}

type Rgb = {
  r: number;
  g: number;
  b: number;
};

export function isValidRegionNumber(phoneNumber: string, countryCode: string): boolean {
  try {
    return isValidNumberForRegion(phoneNumber, countryCode as CountryCode);
  } catch (err) {
    console.error("Error validating phone number", "7a074e55", { phoneNumber, countryCode }, err);
    return false;
  }
}

export function stripWhitespace(string: string): string {
  if (!string) return "";
  return string.replace(/\s/g, "").trim();
}

export function normaliseMobilePhone(
  mobile_phone: string | undefined,
  mobile_phone_country: string | undefined,
  formatNational = true,
  throwError = true
): string {
  if (!mobile_phone || !mobile_phone_country) throw { code: "15fd706f", msg: "missing mobile phone or country" };
  try {
    const phoneNumber = parsePhoneNumberWithError(mobile_phone, mobile_phone_country as CountryCode);
    if (formatNational) return stripWhitespace(phoneNumber.formatNational());
    return stripWhitespace(phoneNumber.formatInternational()).replace(/-/g, "");
  } catch (err) {
    console.error("Error normalising mobile phone", "fb1baba4", { mobile_phone, mobile_phone_country, formatNational }, err);
    if (throwError) {
      throw { code: "fb1baba4", msg: err.message };
    } else {
      return mobile_phone;
    }
  }
}

export function hexToRgb(hex: string): Rgb | null {
  const sanitizedHex = hex.replace(/\##/, "#");
  const colorParts = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(sanitizedHex);

  if (!colorParts) return null;

  const [, r, g, b] = colorParts;

  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
  } as Rgb;
}

export function hexToRgba(hex: string, opacity: number): string {
  const rgb = hexToRgb(hex);
  if (!rgb) return `rgba(0,0,0,1)`;
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
}

export function getContrastColour(hexcolor: string) {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") hexcolor = hexcolor.slice(1);

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map((hex: string) => hex + hex)
      .join("");
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "#000000" : "#ffffff";
}

export function numberToWord(num: number): string {
  const words = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"];

  return words[num - 1] || num.toString();
}

export function formatPostcode(postcode: string): string {
  return postcode
    .toUpperCase()
    .replace(/[^\w\d\s]+/g, "")
    .replace(/\s+/g, " ")
    .trim();
}

export function generateNanoId(domainSafe = false, length = 12): string {
  // Create a custom nanoid that's safe to use in URIs, etc
  if (domainSafe) {
    const customNanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyz-", 14);
    let nanoId: string;
    do {
      nanoId = customNanoid();
    } while (nanoId.endsWith("-") || nanoId.startsWith("-"));
    return nanoId;
  }
  return nanoid(length);
}

export function isPrStage(stage: string): boolean {
  return !!stage.match(/^pr\d+$/) || stage === "automation";
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isRecord(obj: any): obj is Record<string, any> {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
}

export function isPip(access_level: LambdaRequest.enumAccessLevel): boolean {
  return [LambdaRequest.enumAccessLevel.PUBLIC_IN_PRACTICE, LambdaRequest.enumAccessLevel.PATIENT_IN_PRACTICE].includes(access_level);
}

export function getUserAgent(access_level: LambdaRequest.enumAccessLevel): string {
  if (access_level && isPip(access_level)) return "dentrv2pip";

  return "dentrv2";
}
