import { SubSink } from "subsink";
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BrandInfoEntry } from "../data_model/brand-info";
import { CommonService } from "../shared/services/common.service";
import { JWTService } from "../shared/services/jwt.service";
import { NavigationService } from "../shared/services/navigation.service";
import { PatientsService } from "../shared/services/patients.service";
import { BrandService } from "../shared/services/brand.service";
import { LocalisationService } from "../shared/services/localisation.service";
import { BottomSheetService } from "../shared/services/bottom-sheet.service";
import { RouterModule, RouterOutlet } from "@angular/router";
import { FooterComponent } from "../shared/components/footer/footer.component";
import { UserMenuComponent } from "../shared/components/user-menu/user-menu.component";
import { SHARED } from "src/app/shared/shared";
import { FADE_IN_ANIMATION, ROUTE_ANIMATIONS } from "../shared/utils/animations";
import { LocationService } from "../shared/services/location.service";
import { PipLoginService } from "../shared/services/pip-login.service";
import Bugsnag from "@bugsnag/js";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroArrowLeftOnRectangle } from "@ng-icons/heroicons/outline";

@Component({
  selector: "dentr-main",
  templateUrl: "./main.component.html",
  standalone: true,
  imports: [RouterModule, FooterComponent, UserMenuComponent, SHARED, FooterComponent, NgIconComponent],
  animations: [ROUTE_ANIMATIONS, FADE_IN_ANIMATION],
  host: { class: "block h-full" },
  providers: [provideIcons({ heroArrowLeftOnRectangle })],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewChecked {
  private _subs = new SubSink();
  private _appView: boolean;
  public loading = true;
  public brand: BrandInfoEntry;
  public cachedBrand: BrandInfoEntry;
  public showMenu = false;
  get appView(): boolean {
    return this._appView;
  }
  @Input() set appView(value: boolean) {
    this._appView = value;
    this.hideUserMenu = this._jwtService.isPatientUnauthenticated() || !this._jwtService.isLoggedIn() || this._appView;
  }

  /**
   * ** IMPORTANT **
   *
   * Do not remove this function because it prevents PI from being exposed in Safari after the patient has logged out by clicking the back button
   *
   * @param event
   */
  @HostListener("window:pageshow", ["$event"])
  public onWindowShow(event) {
    if (event.persisted && !this._jwtService.isLoggedIn()) {
      // Clear the body to ensure that the page does not expose any PI
      const body = document.querySelector("body");
      if (body) body.innerHTML = "";

      // Reload the page to ensure that we get go back to the login screen
      window.location.reload();
    }
  }

  @HostListener("document:click")
  @HostListener("document:keypress")
  @HostListener("document:touchstart")
  @HostListener("document:touchend")
  @HostListener("document:touchcancel")
  @HostListener("document:touchmove")
  public onPatientActivity() {
    this._pipLoginService.activity();
  }

  @ViewChild("mainContentWrapper") mainContentWrapper: ElementRef;
  public hideUserMenu = true;

  constructor(
    private _commonService: CommonService,
    private _navigationService: NavigationService,
    private _jwtService: JWTService,
    private _brandService: BrandService,
    private _localisationService: LocalisationService,
    private _bottomSheetService: BottomSheetService,
    private _cdr: ChangeDetectorRef,
    private _locationService: LocationService,
    private _pipLoginService: PipLoginService,
    public patientsService: PatientsService
  ) {}

  // #region Angular lifecycle hooks
  public prepareRoute(outlet: RouterOutlet) {
    // Disable animations for e2e tests
    const isE2E = window.localStorage.getItem("e2e");
    if (isE2E) return;
    return outlet?.isActivated && (outlet.activatedRouteData?.animation ? outlet.activatedRouteData.animation : outlet.activatedRoute);
  }

  // Needed to ensure the animation change detection is triggered correctly
  public ngAfterViewChecked() {
    this._cdr.detectChanges();
  }

  public ngOnInit() {
    Bugsnag.leaveBreadcrumb("Page loaded");

    if (this._locationService.isPairDomain || this._locationService.pathname.endsWith("/pair")) {
      this.loading = false;
      return;
    }

    if (this._jwtService.isPatientInPractice()) {
      this._pipLoginService.startInactivityMonitor();
    }

    this._commonService.getCommonData(true);

    // Subset of the brand info that is cached in the browser from the app initialization. Keeping this separate from the brand property to ensure that we do not get change detection issues with brand data
    this.cachedBrand = this._brandService.brand;
    this._bindEvents();

    if (this._locationService.pathname !== "/login/redirect") this._jwtService.getJWT();

    if (!this._inIframe() && this._jwtService.isImpersonating()) {
      console.log("Logging out because we are impersonating but not in an iframe");
      this._jwtService.signout();
    }
  }

  public signout(): void {
    const isPip = this._jwtService.isPip();
    if (isPip) this._pipLoginService.logoutPatientInPractice();
    else this._jwtService.signout();
  }

  public get showFooter(): boolean {
    if (this.isPip) return false;
    if (this.appView) return false;

    return true;
  }

  public get isPip(): boolean {
    return this._jwtService.isPip();
  }

  public get isPatientUnauthenticated() {
    return this._jwtService.isPatientUnauthenticated();
  }

  private _inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }
  // #endregion

  // #region event subscriptions
  private _bindEvents() {
    this._subs.sink = this._commonService.onInitData.subscribe(
      (data) => {
        const content_wrapper = document.getElementById("content-wrapper");
        if (content_wrapper) content_wrapper.remove();
        this._localisationService.country_code = data.practice.iso_country_code;

        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );

    this._subs.sink = this._bottomSheetService.onBottomSheetHeight.subscribe((height) => {
      this.mainContentWrapper.nativeElement.style["padding-bottom"] = `${height}px`;
    });
  }
  // #endregion

  // #region Misc methods
  public navigateToHome() {
    this._navigationService.navigate("/");
  }

  public get isLoggedIn() {
    return this._jwtService.isLoggedIn();
  }
  // #endregion
}
