import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject, merge, of } from "rxjs";
import { filter, take } from "rxjs/operators";
import { SubSink } from "subsink";
import jwtDecode from "jwt-decode";
import { CacheService } from "./cache.service";
import { LambdaRequest } from "@apis/_core/types/LambdaRequest";
import { isOnUnsupportedPage } from "../utils/unsupported";
import { isPip } from "@shared/utils";

const PUBLIC_IN_PRACTICE_JWT_STORAGE_KEY = "public_in_practice_jwt";
@Injectable({
  providedIn: "root",
})
export class JWTService implements OnDestroy {
  public onJWTChanged: BehaviorSubject<any> = new BehaviorSubject([]);
  public onSessionIdChanged: Subject<string> = new Subject<string>();
  public sites: any = [];

  private _subs = new SubSink();
  private _internalJwtParsed: Record<string, any> | null = null;

  private get _jwtParsed(): Record<string, any> | null {
    if (this._internalJwtParsed) {
      const timestamp = new Date().getTime() / 1000;

      if (timestamp > this._internalJwtParsed.exp || this._internalJwtParsed?.version !== "3.0.0") {
        this._internalJwtParsed = null;
        throw new Error("JWT expired");
      }

      if (this._internalJwtParsed.payment_plan_id) {
        this._internalJwtParsed.payment_plan_id = Number(this._internalJwtParsed.payment_plan_id);
      }
    }
    return this._internalJwtParsed;
  }

  private set _jwtParsed(jwtParsed: Record<string, any> | null) {
    this._internalJwtParsed = jwtParsed;
  }

  constructor(private _cacheService: CacheService) {}

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public isPUBLIC() {
    return LambdaRequest.publicAccessLevels.includes(this.getJWT("access_level") as LambdaRequest.enumAccessLevel);
  }

  public isPublicInPractice() {
    return this.getJWT("access_level") === LambdaRequest.enumAccessLevel.PUBLIC_IN_PRACTICE;
  }

  public isPatientInPractice() {
    return this.getJWT("access_level") === LambdaRequest.enumAccessLevel.PATIENT_IN_PRACTICE;
  }

  public isPip() {
    const access_level = this.getJWT("access_level");
    return isPip(access_level);
  }

  public isPatientUnauthenticated() {
    return [LambdaRequest.enumAccessLevel.PATIENT_UNAUTHENTICATED, LambdaRequest.enumAccessLevel.PATIENT_IN_PRACTICE].includes(this.getJWT("access_level"));
  }

  public isImpersonating() {
    return this.getJWT()?.user_impersonating_patient !== undefined;
  }

  public isLoggedIn() {
    return this.getJWT("access_level") === LambdaRequest.enumAccessLevel.PATIENT;
  }

  public isPatient() {
    return this.isLoggedIn() || this.isPatientUnauthenticated();
  }

  public get isRestricted(): boolean {
    // If the patient is in practice then dont offer a restricted mode as we dont care about the features the practice has on/off
    if (this.isPip()) return false;

    const { restricted_mode } = this._getParsedJWT() || {};

    // This can be a string or a boolean depending on whether the JWT was create for a short code or the patient has logged in
    // AWS converts all data to strings when it's passed from the JWT authorizer to the lambda function
    return restricted_mode === true || restricted_mode === "true";
  }

  public get canLogin(): boolean {
    return !["false", false].includes(this.getJWT("can_login"));
  }

  public getFirstName(): any {
    const patient_first_name = this.getJWT("patient_first_name");

    if (patient_first_name) {
      return `${patient_first_name}'s`;
    }
    return "Your";
  }

  private _getParsedJWT() {
    if (!this._jwtParsed) {
      const jwt = this.getJWTString();
      if (jwt) this.setToken(jwt);
    }
    return this._jwtParsed;
  }

  public setPublicToken(jwt: string, forceSwap = false) {
    const jwtCurrrent = forceSwap ? null : this.getJWT(null, false);
    if (jwtCurrrent === null) {
      this.setToken(jwt);
    }
  }

  public handleInvalidToken(reload = true): null {
    this.delete();
    if (reload && !isOnUnsupportedPage()) window.location.reload();
    return null;
  }

  public getJWT(field?: string | null, reloadOnExpired = true): any {
    let parsedJWT: any;

    try {
      parsedJWT = this._getParsedJWT();
    } catch {
      return this.handleInvalidToken(reloadOnExpired);
    }
    if (parsedJWT) {
      if (field && parsedJWT[field] !== undefined) return parsedJWT[field];
      return parsedJWT;
    }

    return null;
  }

  public getJWTString(): string {
    return this._cacheService.get("jwt") ?? "";
  }

  public delete() {
    this._cacheService.delete("jwt");
  }

  public setToken(jwt: string) {
    const existing = this._getExistingToken();
    this._jwtParsed = this._parseJwt(jwt);
    this._cacheService.set("jwt", jwt);
    this.onJWTChanged.next({ status: "UPDATED", jwt: this._jwtParsed });

    if (existing?.sid !== this._jwtParsed?.sid) {
      this.onSessionIdChanged.next(this._jwtParsed?.sid);
    }

    if (this._jwtParsed?.access_level === LambdaRequest.enumAccessLevel.PUBLIC_IN_PRACTICE) {
      this._cacheService.set(PUBLIC_IN_PRACTICE_JWT_STORAGE_KEY, jwt);
    }
  }

  private _getExistingToken(): Record<string, any> {
    const jwt = this.getJWTString();

    if (!jwt) return {};

    return this._parseJwt(jwt) || {};
  }

  public signout() {
    this._internalJwtParsed = null;
    this.delete();
  }

  /**
   * Waits for the JWT to be available so that API calls that require it can be delayed
   */
  public waitForJwt(): Observable<any> {
    return merge(this.onJWTChanged, of({ jwt: this.getJWT() })).pipe(
      filter((status) => status && status.jwt),
      take(1)
    );
  }

  private _parseJwt(token): Record<string, any> | null {
    const jwt = jwtDecode(token);
    if (!jwt) return null;
    return jwt;
  }

  /**
   * Renames the current (portal in practice) jwt and replaces it with the patient in practice jwt
   */
  public loginPatientInPractice(token: string) {
    this.setToken(token);
  }

  public logoutPatientInPractice() {
    this.signout();
    const publicInPracticeJwt = this._cacheService.get(PUBLIC_IN_PRACTICE_JWT_STORAGE_KEY);
    if (publicInPracticeJwt) this.setToken(publicInPracticeJwt);
  }
}
