import { DOCUMENT } from "@angular/common";
import { Component, HostListener, OnDestroy, ChangeDetectorRef, Renderer2, Inject, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import dayjs from "dayjs";
import { timer } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SubSink } from "subsink";
import { ChatComponent } from "./chat/chat.component";
import { MainComponent } from "./main/main.component";
import { NotificationComponent } from "./shared/components/notification/notification.component";
import { FeatureFlagsService } from "./shared/services/feature-flags.service";
import { JWTService } from "./shared/services/jwt.service";
import { NotificationInstance, NotificationService, NotificationTypes } from "./shared/services/notification.service";
import { OverlayService } from "./shared/services/overlay.service";
import { PushUpdatesService } from "./shared/services/push-updates.service";
import { FADE_IN_ANIMATION, FAST_ANIMATION_DURATION } from "./shared/utils/animations";
import { CommonService } from "./shared/services/common.service";
import { LoginV4Service } from "./shared/services/login-v4.service";
import { AppointmentsService } from "./shared/services/appointments.service";
import { E_Appointments_When } from "@backend/graph/appointments/appointment-base";
import { SHARED } from "./shared/shared";
import { NavigationService } from "./shared/services/navigation.service";
import { CacheService } from "./shared/services/cache.service";
import { Constants } from "src/constants";

declare global {
  interface Window {
    clientTracker: any;
    ga: any;
    hj: any;
    gtag: any;
    STAGE: string | undefined | null;
  }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [NotificationComponent, SHARED, MainComponent, ChatComponent],
  animations: [FADE_IN_ANIMATION],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = "app";
  private _subs = new SubSink();
  public loading = true;
  public appView = false;
  public isOverlayOpen: boolean;
  public isOverlayCloseable = true;
  public installPrompt: any;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler() {
    this.closeOverlay();
  }
  @HostListener("window:unload", ["$event"]) unloadHandler() {}
  @HostListener("window:message", ["$event"]) onMessage(event) {
    // Used when the patient app is being shown in an iframe so we can logout before changing to a different portal/user
    if ((event.origin.endsWith(".dentr.io") || event.origin.endsWith(".dentr.net")) && event.data === "signout") {
      this._commonService.signout();
    }
  }

  constructor(
    private _router: Router,
    private _jwtService: JWTService,
    private _activatedRoute: ActivatedRoute,
    private _overlayService: OverlayService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _renderer: Renderer2,
    private _featureFlagsService: FeatureFlagsService,
    private _pushUpdatesService: PushUpdatesService,
    private _notificationService: NotificationService,
    private _navigationService: NavigationService,
    private _commonService: CommonService,
    private _loginV4Service: LoginV4Service,
    private _appointmentsService: AppointmentsService,
    private _cacheService: CacheService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._subs.sink = this._overlayService.onOverlayVisibilityChange.subscribe((is_open) => {
      this.isOverlayOpen = is_open;
      this._changeDetectorRef.detectChanges();
      const method = is_open ? "addClass" : "removeClass";
      this._renderer[method](this._document.body, "overflow-hidden");
    });
    this._subs.sink = this._overlayService.onOverlayCloseableChange.subscribe((isOverlayCloseable) => {
      this.isOverlayCloseable = isOverlayCloseable;
    });

    this._subs.sink = this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          const isMyDental = event.url.includes("/my-dental");
          const isPublic = this._jwtService.isPUBLIC();
          if (isMyDental && isPublic) {
            this._navigationService.navigate("/");
            return;
          }

          const has_shortcut = this._activatedRoute.snapshot.queryParamMap.get("shortcut");
          if (has_shortcut) {
            this._cacheService.setSession(Constants.PWA_SHORTCUT_STORAGE_KEY, has_shortcut);
          }

          this.loading = true;
          // Manual url tracking for HotJar
          if (window.hj) window.hj("stateChange", event.url);
          if (window.ga) {
            (<any>window).ga("set", "page", event.urlAfterRedirects);
            (<any>window).ga("send", "pageview");
            if (window.clientTracker) {
              (<any>window).ga("clientTracker.set", "page", event.urlAfterRedirects);
              (<any>window).ga("clientTracker.send", "pageview");
            }
          }

          try {
            setTimeout(() => {
              document.body.scrollTop = 0;
              window.scrollTo(0, 0);
            }, FAST_ANIMATION_DURATION); // Delay to match the animation duration of the router outlet
          } catch (err) {
            return err;
          }
        }),
        map(() => this._activatedRoute.snapshot)
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        while (route.firstChild) {
          route = route.firstChild;
          this.appView = !!route.data.appView;
        }
        this.loading = false;
        return route;
      });
  }

  ngOnInit() {
    // If we have a redirect path then the appointments call wont be triggered so we need to trigger it here. This will in turn trigger the patient actions call
    if (this._loginV4Service.redirectPath) this._appointmentsService.getAppointments(E_Appointments_When.FUTURE);
    if (this._featureFlagsService.recaptcha) this._addGoogleRecaptchaScript();

    const isPatient = this._jwtService.isPatient();
    const pwaStorageShortcut = this._cacheService.getSession(Constants.PWA_SHORTCUT_STORAGE_KEY);

    // If we have a saved shortcut in session storage from clicking a PWA shortcut, navigate to that page
    if (pwaStorageShortcut && isPatient) {
      this._navigationService.navigate(`/my-dental/${pwaStorageShortcut}`);
      this._cacheService.deleteSession(Constants.PWA_SHORTCUT_STORAGE_KEY);
    }

    this._pushUpdatesService.connect(this._jwtService.getJWTString());
    this._subs.sink = this._pushUpdatesService.downForMaintenanceSubject.pipe(filter((data) => data.status === "down")).subscribe((data) => {
      this._notificationService.open(
        new NotificationInstance({
          title: "Maintenance advance notice",
          body: `Portal will go down for maintenance at ${dayjs()
            .add(data.delay / 1000, "seconds")
            .format("h:mm a")}`,
          type: NotificationTypes.INFO,
          timeout: data.delay,
        })
      );

      this._subs.sink = timer(data.delay).subscribe(() => {
        window.location.reload();
      });
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public closeOverlay(): void {
    if (!this.isOverlayCloseable || !this.isOverlayOpen) return;

    this._overlayService.close();
  }

  private _addGoogleRecaptchaScript() {
    const script = this._document.createElement("script");
    const head = this._document.getElementsByTagName("head")[0];

    script.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${environment.GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;

    head.appendChild(script);
  }
}
