import { Injectable } from "@angular/core";
import { AppointmentMetricProperties, APPOINTMENT_METRIC } from "@backend/common/analytics/appointment-metrics";
import { E_Patient_Actions_Type } from "@backend/graph/patient_actions/patient-action-base";
import dayjs from "dayjs";
import { I_SelectedAppointmentSlot } from "src/app/bookable-appointments/availability/slot-basketless/slot.component";
import { AnalyticsService } from "../analytics.service";
import { JWTService } from "../jwt.service";
import { CacheService } from "../cache.service";
import Bugsnag from "@bugsnag/js";
import { BookableAppointmentEntry } from "src/app/data_model/bookable-appointment";
import { PersistentObjectFactory } from "../../utils/persistent-object";
import { PatientActionsCacheService } from "../patient-actions-cache.service";

const CACHE_KEY = "appointment-metric-properties";

@Injectable({
  providedIn: "root",
})
export class AppointmentAnalyticsService {
  private _cancelledReasons = new Array<string>();
  //this should be kept private - the set function also writes to storage
  private readonly _PROPERTIES_PROXY: AppointmentMetricProperties;
  private _persistentObjectFactory: PersistentObjectFactory;
  constructor(private _analyticsService: AnalyticsService, private _jwtService: JWTService, private _cacheService: CacheService
    ,private _patientActionsCacheService: PatientActionsCacheService
             ) {
    this._persistentObjectFactory = new PersistentObjectFactory(this._cacheService, CACHE_KEY);
    this._PROPERTIES_PROXY = this._getPropertiesFromCache();
  }

  private _getPropertiesFromCache(): AppointmentMetricProperties {
    return this._persistentObjectFactory.create(new AppointmentMetricProperties());
  }

  private _clearProperties() {
    this._persistentObjectFactory.clear(this._PROPERTIES_PROXY);
  }

  //need to pass in patient actions service to avoid cyclic dependencies
  public trackBookingStarted() {
    try {
      this._clearProperties();

      const { referrer } = this._jwtService.getJWT();
      const recalls_due = this._patientActionsCacheService.patientActions
        .map((a) => a.type)
        .filter((type) => type === E_Patient_Actions_Type.ROUTINE_DENTAL_EXAM || type === E_Patient_Actions_Type.ROUTINE_HYGIENE_APPT);

      this._PROPERTIES_PROXY.recalls_due = recalls_due;
      this._PROPERTIES_PROXY.referrer = referrer;

      this._analyticsService.track(new APPOINTMENT_METRIC.AppointmentBookingStarted(this._PROPERTIES_PROXY));
    } catch (e) {
      this._errorAndNotifyBugsnag("error tracking booking started metric", e);
    }
  }

  public trackCategorySelected(category_name: string, site_appointment_types: BookableAppointmentEntry[]) {
    try {
      const reasons = site_appointment_types.map((type) => type.reason);
      const rebooking = !!this._cancelledReasons.find((r) => reasons.includes(r));

      this._PROPERTIES_PROXY.category_name = category_name;
      this._PROPERTIES_PROXY.rebooking = rebooking;
      this._analyticsService.track(new APPOINTMENT_METRIC.AppointmentCategorySelected(this._PROPERTIES_PROXY));
    } catch (e) {
      this._errorAndNotifyBugsnag("error tracking category selected metric", e);
    }
  }

  public addCancelledReason(reason: string) {
    this._cancelledReasons.push(reason);
  }

  public trackTypeSelected(appointment_name: string, deposit: number | null, nhs: boolean, price: number | null, book_together: boolean) {
    try {
      this._PROPERTIES_PROXY.appointment_name = appointment_name;
      this._PROPERTIES_PROXY.deposit = deposit;
      this._PROPERTIES_PROXY.nhs = nhs;
      this._PROPERTIES_PROXY.price = price;
      this._PROPERTIES_PROXY.book_together = book_together;
      this._analyticsService.track(new APPOINTMENT_METRIC.AppointmentTypeSelected(this._PROPERTIES_PROXY));
    } catch (e) {
      this._errorAndNotifyBugsnag("error tracking type selected metric", e);
    }
  }

  public trackAvailabilitySearched(number_of_available_slots: number, first_availability_days_ahead: number | null) {
    try {
      const new_patient = !this._jwtService.isPatient();

      this._PROPERTIES_PROXY.number_of_available_slots = number_of_available_slots;
      this._PROPERTIES_PROXY.first_availability_days_ahead = first_availability_days_ahead;
      this._PROPERTIES_PROXY.new_patient = new_patient;

      this._analyticsService.track(new APPOINTMENT_METRIC.AppointmentAvailabilitySearched(this._PROPERTIES_PROXY));
    } catch (e) {
      this._errorAndNotifyBugsnag("error tracking type selected metric", e);
    }
  }

  private _calculateDaysAhead(startTimeDayJs: dayjs.Dayjs) {
    return startTimeDayJs.startOf("d").diff(dayjs().startOf("d"), "d");
  }

  public trackSlotConfirmed(selected_appointment_slot: I_SelectedAppointmentSlot) {
    try {
      const { site_id, start_time, practitioner } = selected_appointment_slot;
      const startTimeDayJs = dayjs(start_time);

      const { first_name, last_name, id: practitioner_id } = practitioner;

      const hour_of_day = startTimeDayJs.hour();
      const days_ahead = this._calculateDaysAhead(startTimeDayJs);
      const practitioner_name = `${first_name} ${last_name}`;

      this._PROPERTIES_PROXY.site_id = site_id;
      this._PROPERTIES_PROXY.hour_of_day = hour_of_day;
      this._PROPERTIES_PROXY.days_ahead = days_ahead;
      this._PROPERTIES_PROXY.practitioner_name = practitioner_name;
      this._PROPERTIES_PROXY.practitioner_id = practitioner_id;

      this._analyticsService.track(new APPOINTMENT_METRIC.AppointmentSlotConfirmed(this._PROPERTIES_PROXY));
    } catch (e) {
      this._errorAndNotifyBugsnag("error tracking slot confirmed metric", e);
    }
  }

  public trackBooked(payment_method?: string) {
    try {
      this._PROPERTIES_PROXY.payment_method = payment_method;
      this._analyticsService.track(new APPOINTMENT_METRIC.AppointmentBooked(this._PROPERTIES_PROXY));
      this._clearProperties();
    } catch (e) {
      this._errorAndNotifyBugsnag("error tracking slot confirmed metric", e);
    }
  }

  private _errorAndNotifyBugsnag(message: string, e: Error) {
    Bugsnag.notify(message);
    console.error(message, e);
  }
}
