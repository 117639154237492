import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from "@angular/core";
import { environment } from "./environments/environment";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { APP_ROUTES, LoginAuthGuard, PatientTypeSelectionAuthGuard, RestrictedAuthGuard } from "./app/app.routing";
import { AppInitService } from "./app/shared/services/app-init.service";
import { HttpErrorHandler } from "./app/shared/services/http-error-handler.service";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AppComponent } from "./app/app.component";
import { HttpInterceptorProviders } from "./app/shared/http-interceptors";
import { CurrencyPipe } from "@angular/common";
import { JWTService } from "./app/shared/services/jwt.service";
import { BookableAppointmentsAuthGuard } from "./app/bookable-appointments/bookable-appointments.routing";
import { provideServiceWorker } from "@angular/service-worker";

if (environment.production) {
  enableProdMode();
}

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES),
    AppInitService,
    JWTService,
    PatientTypeSelectionAuthGuard,
    RestrictedAuthGuard,
    LoginAuthGuard,
    BookableAppointmentsAuthGuard,
    HttpInterceptorProviders,
    HttpErrorHandler,
    CurrencyPipe,
    { provide: ErrorHandler, useFactory: errorHandlerFactory, deps: [AppInitService] },
    {
      provide: APP_INITIALIZER, // see https://angular.io/api/core/APP_INITIALIZER
      useFactory: (appInitService: AppInitService) => (): Promise<any> => appInitService.init(),
      deps: [AppInitService],
      multi: true,
    },
    importProvidersFrom(BrowserModule, BrowserAnimationsModule, HttpClientModule, AngularSvgIconModule.forRoot()),
    provideServiceWorker("ngsw-worker.js", {
      enabled: environment.PWA_INSTALLABLE === "true", // Only enable service workers when PWA_INSTALLABLE is true so we can test on sandbox prior to release to production
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
}).catch((err) => console.error(err));
