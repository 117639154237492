import forOwn from "lodash/forOwn";
import { AppointmentBase, AppointmentPractitionerBase, CancellationInfoBase } from "@backend/graph/appointments/appointment-base";
import { I_SelectedAppointmentSlot } from "../bookable-appointments/availability/slot-basketless/slot.component";

export class CancellationInfoEntry extends CancellationInfoBase {}

export class AppointmentPractitionerEntry extends AppointmentPractitionerBase {}

export class AppointmentEntry extends AppointmentBase {
  constructor(baseItem?: AppointmentBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}

export class AllAppointmentsEntry {
  public future: Array<AppointmentEntry>;
  public past: Array<AppointmentEntry>;
}
export enum E_AppointmentEventActions {
  APPOINTMENTS_OPEN_FOR_CHECKIN = "APPOINTMENTS_OPEN_FOR_CHECKIN",
  SUCCESSFULLY_ADDED_COMMENT = "SUCCESSFULLY_ADDED_COMMENT",
  ERROR_ADDING_COMMENT = "ERROR_ADDING_COMMENT",
  APPOINTMENTS_UPDATED = "APPOINTMENTS_UPDATED",
  SLOT_SELECTED = "SLOT_SELECTED",
  SLOT_UNAVAILABLE = "SLOT_UNAVAILABLE",
  BOOKING_APPOINTMENTS = "BOOKING_APPOINTMENTS",
  CHECKING_BOOKING_SESSION = "CHECKING_BOOKING_SESSION",
  BOOKING_SESSION_OK = "BOOKING_SESSION_OK",
}
export class AppointmentEvents {
  public action: E_AppointmentEventActions;
  public appointments?: Array<AppointmentEntry>;
  public slots?: Array<I_SelectedAppointmentSlot>;
}
