import { Injectable } from "@angular/core";
import { NHS } from "@apis/_core/types/nhs.interface";
import { E_Patient_Actions_Type } from "@backend/graph/patient_actions/patient-action-base";
import { BehaviorSubject } from "rxjs";
import { Constants } from "src/constants";
import { SubSink } from "subsink";
import { CacheService } from "./cache.service";
import { GAService } from "./ga.service";
import { HttpService } from "./http.service";
import { LoggerService } from "./logger.service";
import { NavigationService } from "./navigation.service";
import { NotificationService, NotificationTypes } from "./notification.service";
import { PatientsService } from "./patients.service";

interface I_NHS_PR {
  status: "LOADING" | "LOADED" | "SAVED";
  data?: NHS.PR_FORM.Public.get;
}

const GRAPHQL_QUERY = (languageCode: string, appointmentId?: string | null) => {
  let args = `language_code: "${languageCode}"`;

  if (appointmentId) args += `, appointment_id: "${appointmentId}"`;

  return `{
  patient {
    age
    date_of_birth
    nhs_number
    nhs_country
    nhs_pr(${args}) {
      form_required
      reason
      reason_text
      site_id
      appointment_start_time
      appointment_id
      legal_text {
        BASIC
        EXEMPTION_DEFAULT
      }
      patient_exemption {
        exemption_code
        expiry_date
        relevant_date
        remission_amount
        supporting_details
        id
        updated_at
        description
        check_everytime
        created_at
        patient_id
        user_id
        signed
      }
      exemption_options {
        exemption_code
        description
        intro
        check_everytime
        group
        details {
          title
          intro
          fields {
            field
            label
            type
            placeholder
            more_info
            validation {
              pattern
              date {
                type
                value
              }
              required
              number {
                min
                max
              }
            }
          }
        }
        expiry_date_rule
        legal_text
        rules {
          age {
            min
            max
          }
          gender
        }
        output {
          proxy_signer {
            proxy_signers_relationship
            proxy_signers_name
          }
          patient_exemption {
            exemption_code
            relevant_date
            remission_amount
            supporting_details
            ni_number
            patient_id
            expiry_date
          }
          encoded_signature
          date_of_birth
        }
      }
      display_groups {
        id
        intro
        items {
          exemption_code
          description
          intro
          check_everytime
          group
          details {
            title
            intro
            fields {
              field
              label
              type
              placeholder
              validation {
                pattern
                date {
                  type
                  value
                }
                required
                number {
                  min
                  max
                }
              }
            }
          }
          expiry_date_rule
          legal_text
          rules {
            age {
              min
              max
            }
            gender
          }
          output {
            proxy_signer {
              proxy_signers_relationship
              proxy_signers_name
            }
            patient_exemption {
              exemption_code
              relevant_date
              remission_amount
              supporting_details
              ni_number
              patient_id
              expiry_date
            }
            encoded_signature
            date_of_birth
          }
        }
      }
      language_codes
      language_code
      url
      updated_at
    }
  }
}`;
};
@Injectable({
  providedIn: "root",
})
export class NhsPrService {
  private _subs = new SubSink();
  private _requirePatientNhsNumber = false;
  public new_pr_form: NHS.PR_FORM.Public.post = {};
  public selected_exemption: NHS.PatientExemptions.internal.NHS_EXEMPTION_OPTION = {};
  public pr_data: NHS.PR_FORM.Public.get | null = null;
  public onPrDataChanged: BehaviorSubject<I_NHS_PR | null> = new BehaviorSubject(null);
  public langSwitch = {
    buttonText: "Cymraeg",
    show: false,
    language_code: "en",
    switch_to_language_code: "wa",
    switch_to_buttonText: "English",
  };
  private _isFetching = false;
  private _formUrl: string;
  constructor(
    private _httpService: HttpService,
    private _navigationService: NavigationService,
    private _notificationService: NotificationService,
    private _gaService: GAService,
    private _loggerService: LoggerService,
    private _patientsService: PatientsService,
    private _cacheService: CacheService
  ) {}

  public get requirePatientNhsNumber(): boolean {
    return this._requirePatientNhsNumber;
  }

  public set requirePatientNhsNumber(value: boolean) {
    this._requirePatientNhsNumber = value;
  }

  public get formUrl(): string {
    return this._formUrl;
  }

  getPrData(force?: boolean, language_code = "en"): void {
    if (this._isFetching) {
      const interval = setInterval(() => {
        if (!this._isFetching) {
          clearInterval(interval);
          return;
        }
      }, 300);
      return;
    }
    if (this.pr_data && !force) {
      this.onPrDataChanged.next({
        status: "LOADED",
        data: this.pr_data,
      });
    } else {
      this._isFetching = true;
      this._subs.sink = this._httpService.query(GRAPHQL_QUERY(language_code)).subscribe(
        (response) => {
          const pr_data: NHS.PR_FORM.Public.get = {
            age: response.data.patient.age,
            date_of_birth: response.data.patient.date_of_birth,
            ...response.data.patient.nhs_pr,
          };

          if (this._patientsService.patientInfo) {
            const { nhs_number, nhs_country } = this._patientsService.patientInfo;
            this.requirePatientNhsNumber = !!(!nhs_number && nhs_country === "WA");
          }

          if (pr_data.patient_exemption?.exemption_code) this.selected_exemption = pr_data.patient_exemption;
          if (!pr_data.patient_exemption?.description) delete pr_data.patient_exemption;

          this.pr_data = pr_data;
          this.langChecker();
          this.onPrDataChanged.next({
            status: "LOADED",
            data: pr_data,
          });
          this._isFetching = false;
        },
        () => {
          this._isFetching = false;
          this.saveError();
        }
      );
    }
  }

  public getLegalText(): string[] {
    const legalText: string[] = [];
    if (this.pr_data?.legal_text) {
      if (this.selected_exemption?.legal_text?.length) {
        this.selected_exemption.legal_text.forEach((i) => {
          if (this.pr_data?.legal_text) legalText.push(this.pr_data.legal_text[i]);
        });
      } else {
        for (const key in this.pr_data.legal_text) {
          if (Object.prototype.hasOwnProperty.call(this.pr_data.legal_text, key)) {
            legalText.push(this.pr_data.legal_text[key]);
          }
        }
      }
    }
    return legalText;
  }
  changeLanguage() {
    this.langSwitch.buttonText = this.langSwitch.switch_to_buttonText;
    this.getPrData(true, this.langSwitch.switch_to_language_code);
  }

  saveSuccess() {
    this._gaService.action("nhs_pr_exemption_sign_success");
    this._navigationService.navigate("/my-dental");
  }

  saveError() {
    this._navigationService.navigate("/my-dental/nhs-pr/terms");
    this._notificationService.open({
      type: NotificationTypes.ERROR,
      title: "There was an unexpected error, please try again",
    });
    this._gaService.action("nhs_pr_exemption_sign_error");
    try {
      this._loggerService.error("nhs_pr_exemption_sign_error");
    } catch (err) {}
  }

  private _updateAction(): void {
    const actions_session = JSON.parse(this._cacheService.getSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY) || "{}");
    const has_action = actions_session?.actions?.find((i) => i.type === E_Patient_Actions_Type.NHS_PR);

    if (!has_action || !this.formUrl) return;
    has_action.form_url = this.formUrl;
    this._cacheService.setSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY, JSON.stringify(actions_session));
  }

  public signForm(): void {
    this.onPrDataChanged.next({
      status: "LOADING",
    });
    if (this.pr_data) {
      this.pr_data.form_required = false;
      this.pr_data.patient_exemption = this.new_pr_form.patient_exemption;
    }

    const mutation = this._getGraphQlMutation();
    this._httpService.query(mutation).subscribe((response) => {
      try {
        if (!response.errors?.length) {
          this.onPrDataChanged.next({
            status: "SAVED",
          });
          this.new_pr_form = {};
          this.pr_data = null;
          this._formUrl = response.data.createPatientNhsPrExemption.url;
          this._updateAction();
          this.saveSuccess();

          if (this.new_pr_form.date_of_birth && this._patientsService.patientInfo && !this._patientsService.patientInfo?.date_of_birth) {
            this._patientsService.patientInfo.date_of_birth = this.new_pr_form.date_of_birth;
          }
        } else {
          throw response.errors[0].message;
        }
      } catch (err) {
        this._handleCreateError(err);
      } finally {
        this.getPrData(true);
      }
    });
  }

  private _handleCreateError(error?: string) {
    if (error === "NOT_REQUIRED__ALREADY_SIGNED") {
      this._gaService.action("nhs_pr_NOT_REQUIRED__ALREADY_SIGNED");
      this._backToForm();
    } else if (error === "INCORRECT_DOB") {
      this.new_pr_form.date_of_birth = undefined;
      this.saveError();
      this._backToForm();
    } else {
      this.getPrData(true);
      this.saveError();
    }
  }

  private _getGraphQlMutation(): string {
    const patientExemption = new Array<string>();
    const proxySigner = new Array<string>();

    for (const [key, value] of Object.entries(this.new_pr_form.patient_exemption || {})) {
      if (value) {
        patientExemption.push(`${key}: "${value}"`);
      }
    }

    for (const [key, value] of Object.entries(this.new_pr_form.proxy_signer || {})) {
      if (value) {
        proxySigner.push(`${key}: "${value}"`);
      }
    }

    let mutation = `mutation {
      createPatientNhsPrExemption(new_item: {
        signature_used: ${this.new_pr_form.signature_used},
        date_of_birth: "${this.new_pr_form.date_of_birth}",
        patient_exemption: {
          ${patientExemption.join(",\n")}
        },`;

    if (proxySigner.length) {
      mutation += `
        proxy_signer: {
          ${proxySigner.join(",\n")}
        },`;
    }

    mutation += `
        encoded_signature: "${this.new_pr_form.encoded_signature}"
      }) {
        error
        url
      }
    }`;

    return mutation;
  }

  private _backToForm(): void {
    this._navigationService.navigate("/my-dental/nhs-pr");
  }

  langChecker() {
    if (this.pr_data?.language_codes?.length === 2) {
      if (this.pr_data.language_code) this.langSwitch.language_code = this.pr_data.language_code;

      switch (this.pr_data.language_code) {
        case "en":
          if (this.pr_data.language_codes.indexOf("cy") > -1) {
            this.langSwitch.buttonText = "Cymraeg";
            this.langSwitch.switch_to_buttonText = "English";
            this.langSwitch.switch_to_language_code = "cy";
            this.langSwitch.show = true;
          }
          break;
        case "cy":
          if (this.pr_data.language_codes.indexOf("en") > -1) {
            this.langSwitch.buttonText = "English";
            this.langSwitch.switch_to_buttonText = "Cymraeg";
            this.langSwitch.switch_to_language_code = "en";
            this.langSwitch.show = true;
          }
          break;
        default:
          break;
      }
    } else {
      this.langSwitch.show = false;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
