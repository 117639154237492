import { AddressableItem } from "../../common/addressable-item";

export class CancellationPolicyBase {
  public readonly can_cancel_online = true;
  public readonly can_cancel_online_up_to_hours = 48;
  public readonly charge_if_less_than_hours = 0;
  public readonly charge_per_minute = 0;
}

export class PatientStatsBase extends AddressableItem {
  public first_appointment_date: string | null;
  public first_exam_date: string | null;
  public last_appointment_date: string | null;
  public last_cancelled_appointment_date: string | null;
  public last_exam_date: string | null;
  public last_fta_appointment_date: string | null;
  public last_scale_and_polish_date: string | null;
  public next_appointment_date: string | null;
  public next_exam_date: string | null;
  public next_scale_and_polish_date: string | null;
  public nhs_exemption_code: string | null;
  public can_book_together_dental: boolean;
  public can_book_together_hygiene: boolean;
}

export class PatientBase extends PatientStatsBase {
  // From Patient
  public acquisition_source_id: string;
  public account_id: number;
  public active: boolean;
  public bad_debtor: boolean;
  public date_of_birth: string;
  public dentist_id: string;
  public dentist_recall_date: string | null;
  public dentist_recall_interval: number | null;
  public email_address: string;
  public emergency_contact_name: string | null;
  public emergency_contact_phone: string | null;
  public emergency_contact_phone_normalized: string | null;
  public emergency_contact_phone_country: string | null;
  public emergency_contact_relationship: string | null;
  public ethnicity: string;
  public first_name: string;
  public gender: "M" | "F";
  public gp_practice_name: string;
  public gp_practice_address_line_1: string;
  public gp_practice_address_line_2: string;
  public gp_practice_address_line_3: string;
  public gp_practice_address_line_4: string;
  public gp_practice_address_line_5: string;
  public gp_practice_postcode: string;
  public home_phone_country: string;
  public home_phone: string;
  public hygienist_id: string;
  public hygienist_recall_date: string | null;
  public hygienist_recall_interval: number | null;
  public id: string; // unique within the practice but reused for different practices
  public last_name: string;
  public marketing: boolean;
  public mobile_phone_country: string;
  public mobile_phone_normalized: string;
  public mobile_phone: string;
  public nhs_number: string;
  public ni_number: string;
  public payment_plan_id: string;
  public preferred_phone_number: number;
  public prevent_appointment_booking: boolean;
  public site_id: string;
  public stripe_id: string;
  public title: string;
  public uuid: string; // globally unique
  public use_email: boolean;
  public use_sms: boolean;
  public work_phone_country: string;
  public work_phone: string;

  // Added by us
  public age: number;
  public cancellation_policy: CancellationPolicyBase;
  public is_nhs: boolean;
  public last_impersonator_login: Date | null;
  public last_patient_login: Date | null;
  public nhs_country: string;
  public portal_url: string;
  public portal_url_full: string;
  public portal_url_is_old_world: boolean;
  public registered_portal_user: boolean;
  public is_account_locked?: boolean;
}
