import { Injectable } from "@angular/core";
import { ICON_SIZES } from "@backend/graph/constants";
import { BrandInfoEntry } from "src/app/data_model/brand-info";
import type { T_BrandThemeOverride } from "./brand.service";
import { JWTService } from "./jwt.service";
import * as palette from "../../../../palette";
import { environment } from "src/environments/environment";
import { LocationService } from "./location.service";
import { WindowService } from "./window.service";

const { host, protocol } = window.location;

export interface I_ManifestShortcut {
  name: string;
  url: string;
  description: string;
  icons: Array<I_ManifestIcon>;
}

export interface I_ManifestScreenshot {
  src: string;
  type: string;
  sizes: string;
  form_factor: string;
  label: string;
}

export interface I_Manifest {
  name: string;
  short_name: string;
  description: string;
  categories: Array<string>;
  start_url: string;
  scope: string;
  background_color: string;
  theme_color: string;
  display?: string;
  orientation: string;
  icons: Array<I_ManifestIcon>;
  screenshots: Array<I_ManifestScreenshot>;
  shortcuts?: Array<I_ManifestShortcut>;
}

const DEFAULT_FAVICON_PATH = `${protocol}//${host}/assets/`;
const DEFAULT_FAVICON_HTML = `<link rel="icon" type="image/x-icon" href="${DEFAULT_FAVICON_PATH}favicon.ico" />`;
const PWA_SCREENSHOTS_PATH = `${protocol}//${host}/assets/screenshots/`;
export const PWA_SHORTCUTS_PATH = `${protocol}//${host}/assets/shortcuts/`;

export interface I_ManifestIcon {
  src: string;
  type: string;
  sizes: string;
}

@Injectable({
  providedIn: "root",
})
export class PwaService {
  constructor(private _jwtService: JWTService, private _locationService: LocationService, private _windowService: WindowService) {}

  public get isPWA(): boolean {
    if ("standalone" in this._windowService.navigator) {
      return this._windowService.navigator.standalone === true;
    }

    const mediaQuery = this._windowService.matchMedia("(display-mode: standalone)");

    return !!mediaQuery?.matches;
  }

  public setupPwa(brand?: BrandInfoEntry, themeOverride?: T_BrandThemeOverride): void {
    this._setupManifest(brand, themeOverride);
    this._setupFavIcons(brand, themeOverride);
    this._addMetaTags();
  }

  private _addMetaTags(): void {
    const themeMetaEl = document.getElementById("meta-theme-color");

    if (themeMetaEl) themeMetaEl.setAttribute("content", palette.grape[500]);
  }

  private _setupManifest(brand: BrandInfoEntry | undefined, themeOverride: T_BrandThemeOverride | undefined): void {
    const manifestEl = document.getElementById("web-manifest");
    const manifest = this._generateManifest(brand, themeOverride);
    const isPiP = this._locationService.isPairDomain || this._jwtService.isPip();

    if (manifestEl && isPiP) manifestEl.setAttribute("href", manifest);
  }

  private _setupFavIcons(brand: BrandInfoEntry | undefined, themeOverride: T_BrandThemeOverride | undefined): void {
    const iconsEl = document.querySelectorAll("link[rel='icon']");
    // Remove all existing icons elements
    iconsEl.forEach((el) => el.remove());

    const iconHtml = !brand?.favicon_preview_url ? DEFAULT_FAVICON_HTML : this._generateIconsMarkup(brand, themeOverride);

    // Add the icons to the page
    document.querySelector("head")?.insertAdjacentHTML("beforeend", iconHtml);
  }

  private _generateManifest(brand: BrandInfoEntry | undefined, themeOverride: T_BrandThemeOverride | undefined): string {
    const isLoggedIn = this._jwtService.isLoggedIn();
    const prefix = isLoggedIn ? "/my-dental" : "";
    const start_url = `${window.location.origin}${prefix}`;

    const manifest: I_Manifest = {
      name: "Dentally Portal",
      short_name: "Dentally Portal",
      description: "Your complete digital in practice assistant. Streamline check-ins, forms and treatment plans with ease.",
      start_url,
      categories: ["health", "medical", "productivity"],
      scope: start_url,
      background_color: "#FFFFFF",
      theme_color: palette.grape[500],
      orientation: "portrait-primary",
      icons: this._generateManifestIcons(brand, themeOverride),
      screenshots: this._generateScreenshots(),
    };

    // Adding a display property makes the PWA installable and passes the audit (https://developer.chrome.com/docs/lighthouse/pwa/installable-manifest)
    if (environment.PWA_INSTALLABLE === "true") manifest.display = "standalone";

    const content = encodeURIComponent(JSON.stringify(manifest));
    const url = "data:application/manifest+json," + content;

    return url;
  }

  private _generateScreenshots(): Array<I_ManifestScreenshot> {
    const screenshots = new Array<I_ManifestScreenshot>();
    const sizes: Record<string, string> = {
      narrow: "320x640",
      wide: "640x480",
    };

    const files: Record<string, string> = {
      start: "Patient check-in",
      medical: "Filling in a medical history form aheaad of an appointment",
      complete: "Ready for an appointment",
    };

    for (const size of Object.keys(sizes)) {
      for (const [key, value] of Object.entries(files)) {
        const src = `${PWA_SCREENSHOTS_PATH}${size}/${key}.png`;
        screenshots.push({
          src,
          type: "image/png",
          sizes: sizes[size],
          form_factor: size,
          label: value,
        });
      }
    }

    return screenshots;
  }

  private _generatePatientShortcuts(start_url: string): Array<I_ManifestShortcut> {
    return [
      {
        name: "Book Appointment",
        url: `${start_url}/book?shortcut=book`,
        description: `Book an appointment with your practice`,
        icons: [
          {
            src: `${PWA_SHORTCUTS_PATH}book.png`,
            type: "image/png",
            sizes: "96x96",
          },
        ],
      },
      {
        name: "My Appointments",
        url: `${start_url}/appointments?shortcut=appointments`,
        description: `Cancel, reschedule or view your appointments`,
        icons: [
          {
            src: `${PWA_SHORTCUTS_PATH}appointments.png`,
            type: "image/png",
            sizes: "96x96",
          },
        ],
      },
      {
        name: "Update Details",
        url: `${start_url}/contact-details?shortcut=contact-details`,
        description: "Update your personal and contact information",
        icons: [
          {
            src: `${PWA_SHORTCUTS_PATH}details.png`,
            type: "image/png",
            sizes: "96x96",
          },
        ],
      },
      {
        name: "Check Balance",
        url: `${start_url}/account?shortcut=account`,
        description: "View your account balance and make payments",
        icons: [
          {
            src: `${PWA_SHORTCUTS_PATH}balance.png`,
            type: "image/png",
            sizes: "96x96",
          },
        ],
      },
    ];
  }

  private _generateManifestIcons(brand: BrandInfoEntry | undefined, themeOverride: T_BrandThemeOverride | undefined): Array<I_ManifestIcon> {
    const icons: Array<I_ManifestIcon> = [
      {
        src: !brand?.favicon_preview_url ? `${DEFAULT_FAVICON_PATH}favicon.ico` : this._generateIconUrl(brand, themeOverride),
        type: "image/x-icon",
        sizes: "16x16",
      },
    ];

    // If there is no custom favicon, then we dont need to generate the custom ones but just use the default
    if (!brand?.favicon_preview_url) {
      icons.push({
        src: `${DEFAULT_FAVICON_PATH}favicon_144x144.png`,
        type: "image/png",
        sizes: "144x144",
      } as I_ManifestIcon);

      return icons;
    }

    for (const size of ICON_SIZES) {
      icons.push({
        src: this._generateIconUrl(brand, themeOverride, size),
        type: "image/png",
        sizes: `${size}x${size}`,
      });
    }
    return icons;
  }

  private _generateIconUrl(brand: BrandInfoEntry, themeOverride: T_BrandThemeOverride | undefined, size?: string): string {
    const brandId = themeOverride?.favicon_preview_url ? themeOverride.owned_brand_id : brand.id;
    const practiceId = this._jwtService.getJWT()?.practice_id;
    if (size) {
      if (!ICON_SIZES.includes(size)) throw new Error(`Invalid icon size: ${size}`);
      return `${protocol}//${host}/cdn/assets/original/favicon/favicon_${size}x${size}_${practiceId}_${brandId}.png`;
    }

    return `${protocol}//${host}/cdn/assets/original/favicon/favicon_${practiceId}_${brandId}.ico`;
  }

  private _generateIconsMarkup(brand: BrandInfoEntry, themeOverride: T_BrandThemeOverride | undefined): string {
    let html = `<link rel="icon" type="image/x-icon" sizes="16x16" href="${this._generateIconUrl(brand, themeOverride)}">`;
    for (const size of ICON_SIZES) {
      html += `<link rel="icon" type="image/png" sizes="${size}x${size}" href="${this._generateIconUrl(brand, themeOverride, size)}">`;
    }
    return html;
  }
}
