export class AppointmentPractitionerBase {
  public active: boolean;
  public colour: string;
  public first_name: string;
  public id: string;
  public image_url: string;
  public last_name: string;
  public role: string;
  public title: string;
  public job_title: string;
  public biography: string;
  public site_name: string;
  public site_address_line_1: string;
  public site_address_line_2: string;
  public site_address_line_3: string;
  public site_county: string;
  public site_postcode: string;
  public site_id: string;
  public site_phone_number: string;
  public bg_class?: string;
  public text_class?: string;
}

export enum E_Appointments_When {
  FUTURE = "FUTURE",
  PAST = "PAST",
}

export enum E_Appointment_Error_Codes {
  CONFLICT = "CONFLICT",
  NOT_FOUND = "NOT_FOUND",
}

export enum E_Appointment_State {
  NOT_CHECKED_IN = "NOT_CHECKED_IN",
  FUTURE_CHECKIN_CLOSED = "FUTURE_CHECKIN_CLOSED",
  FUTURE_CHECKIN_OPEN = "FUTURE_CHECKIN_OPEN",
  FUTURE_LOCKED = "FUTURE_LOCKED",
  FUTURE_EDITABLE = "FUTURE_EDITABLE",
  PENDING = "Pending",
  CONFIRMED = "Confirmed",
  ERROR = "",
  ARRIVED = "Arrived",
  IN_SURGERY = "In surgery",
  DID_NOT_ATTEND = "Did not attend",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
}
export enum E_Appointment_PMS_State {
  ARRIVED = "Arrived",
  CANCELLED = "Cancelled",
  COMPLETED = "Completed",
  CONFIRMED = "Confirmed",
  DID_NOT_ATTEND = "Did not attend",
  IN_SURGERY = "In surgery",
  PENDING = "Pending",
  DELETED = "Deleted", // Not a dentally state, but derived from the webhook event
}

export class AppointmentBase {
  public id?: string;
  public cancellation_info: CancellationInfoBase;
  public created_at?: string;
  public duration: string;
  public finish_time: string;
  public metadata: Record<string, any>;
  public notes: string;
  public patient_id: string;
  public practitioner: AppointmentPractitionerBase;
  public practitioner_site_id: string;
  public practitioner_id: string;
  public reason: string;
  public start_time: string;
  public state: E_Appointment_State;
  public system_state: E_Appointment_PMS_State;
  public treatment_description: string;
  public room_id: string;
  public site_id: string;
  public updated_at?: string;
}

export class CancellationInfoBase {
  public amount: number;
  public can_cancel: boolean;
  public can_cancel_before: string;
  public cancellation_screen: string;
  public charge_after: string;
  public charge_if_less_than_hours: string;
  public minutes_left_to_cancel: number;
  public minutes_left_to_cancel_free: number;
}
