import { SubSink } from "subsink";
import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { BrandService } from "../../services/brand.service";
import { JWTService } from "../../services/jwt.service";
import { NavigationService } from "../../services/navigation.service";
import { NavigationEnd, Router } from "@angular/router";
import { PatientsService } from "../../services/patients.service";
import { SHARED } from "src/app/shared/shared";
import { CommonService } from "../../services/common.service";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { heroArrowLeftOnRectangle, heroIdentification, heroChevronDown } from "@ng-icons/heroicons/outline";

@Component({
  selector: "dentr-user-menu",
  templateUrl: "./user-menu.component.html",
  standalone: true,
  imports: [NgIconComponent, SHARED],
  host: {
    "(document:click)": "onClick($event)",
  },
  providers: [provideIcons({ heroArrowLeftOnRectangle, heroIdentification, heroChevronDown })],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();
  public loading = true;
  public showMenu = false;
  public patientName: string;

  constructor(
    public patientsService: PatientsService,
    private _navigationService: NavigationService,
    private _jwtService: JWTService,
    private _commonService: CommonService,
    private _brandService: BrandService,
    private _router: Router,
    private _eref: ElementRef
  ) {}

  // #region Angular lifecycle hooks
  public ngOnInit() {
    this._bindEvents();
    if (this._jwtService.isLoggedIn() && this.patientsService.patientInfo) {
      const { first_name, last_name } = this.patientsService.patientInfo;
      this.patientName = `${first_name} ${last_name || ""}`.trim();
    }
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }
  // #endregion

  // #region event subscriptions
  private _bindEvents() {
    this._subs.sink = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.showMenu) this.showMenu = false;
    });
  }
  // #endregion

  // #region Navigation methods
  public navigateTo(path) {
    this._navigationService.navigate(path);
    this.showMenu = false;
  }

  public async signOut() {
    await this._commonService.getDomainInfo();
    setTimeout(() => this._commonService.signout(), 100);
  }
  // #endregion

  // #region Misc methods
  public get isLoggedIn() {
    return this._jwtService.isLoggedIn();
  }

  public get isPatient(): boolean {
    return this._jwtService.isPatient();
  }

  public onClick($event: Event) {
    if (!this.isLoggedIn) return;
    const isMenuClick = this._eref.nativeElement.contains($event.target);
    this.showMenu = isMenuClick ? !this.showMenu : false;
  }

  public navigateToLandingPage(): void {
    if (this.isLoggedIn) return;
    this._navigationService.navigate(this._jwtService.isPatientUnauthenticated() ? "/login" : "/");
  }
  // #endregion
}
