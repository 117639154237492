import { Injectable } from "@angular/core";
import { LoggerService } from "./logger.service";
import { ICacheStorage } from "../interfaces/cache-storage.interface";
import { BrowserStorageFactory } from "../utils/browser-storage.factory";
import { E_StorageType } from "../utils/local-storage.util";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private _localStorage: ICacheStorage;
  private _sessionStorage: ICacheStorage;

  public constructor(private _loggerService: LoggerService, browserStorageFactory: BrowserStorageFactory) {
    this._localStorage = browserStorageFactory.create(E_StorageType.LOCAL_STORAGE);
    this._sessionStorage = browserStorageFactory.create(E_StorageType.SESSION_STORAGE);
  }

  public set(key: string, value: any, storage = this._localStorage): void {
    this._loggerService.debug(`Caching payload with cache key '${key}':`, value);
    storage.setItem(key, value);
  }

  public get(key: string, storage = this._localStorage): string | null {
    const result = storage.getItem(key);

    if (result?.constructor === Object && Object.keys(result).length === 0) {
      this._loggerService.debug(`No cached data found for '${key}'.`);
      return null;
    }

    return result;
  }

  public delete(key: string, storage = this._localStorage): void {
    storage.removeItem(key);
  }

  public getJsonSession<T>(key: string): T | null {
    const storageStr = this.get(key, this._sessionStorage);
    if (!storageStr) return null;
    try {
      const storage = JSON.parse(storageStr);
      if (storage.ttl && storage.ttl < Date.now()) {
        this.deleteSession(key);
        return null;
      }
      return storage.value;
    } catch (e) {
      console.error("error parsing storage", { key, storageStr }, e);
      return null;
    }
  }

  public setJsonSession(key: string, value: Record<string, any>, ttl?: number): void {
    const storage: Record<string, any> = { value };
    if (ttl) storage.ttl = ttl;
    const storageString = JSON.stringify(storage);
    this.set(key, storageString, this._sessionStorage);
  }

  public setSession(key: string, value: any): void {
    this.set(key, value, this._sessionStorage);
  }

  public getSession(key: string): string | null {
    return this.get(key, this._sessionStorage);
  }

  public deleteSession(key: string): void {
    this.delete(key, this._sessionStorage);
  }
}
