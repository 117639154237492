import { Injectable } from "@angular/core";
import { RecaptchaService } from "./recaptcha.service";
import { Enums } from "@apis/_core/types/enums";
import { HttpService } from "./http.service";
import { BehaviorSubject, Subject } from "rxjs";
import { PatientEntry } from "src/app/data_model/patient";

export enum E_VerificationCheckResponse {
  SUCCESS = "SUCCESS",
  INVALID = "INVALID",
  ERROR = "ERROR",
}

export enum E_VerificationRequestedResponse {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

@Injectable({
  providedIn: "root",
})
export class VerificationService {
  public onVerificationRequested: Subject<E_VerificationRequestedResponse> = new Subject<E_VerificationRequestedResponse>();
  public onVerificationCheck = new BehaviorSubject<E_VerificationCheckResponse | null>(null);
  constructor(private _recaptchaService: RecaptchaService, private _http: HttpService) {}

  public async sendNewPatientVerificationCode(action: Enums.Google_Recaptcha_Action, site_id: string, new_patient: PatientEntry): Promise<void> {
    const recaptcha_token = await this._generateRecaptchaToken(action);
    const query = `mutation {
      requestNewPatientSmsVerificationCode(action: ${action}, site_id: "${site_id}", recaptcha_token: "${recaptcha_token}",  new_patient:{
        first_name: "${new_patient.first_name}",
        last_name: "${new_patient.last_name}",
        email_address: "${new_patient.email_address}",
        mobile_phone: "${new_patient.mobile_phone}",
        mobile_phone_country: "${new_patient.mobile_phone_country}"
      })
    }`;

    this._http.query(query).subscribe((response) => {
      if (response.errors) {
        this.onVerificationRequested.next(E_VerificationRequestedResponse.ERROR);
        return;
      }
      if (response.data.requestNewPatientSmsVerificationCode) this.onVerificationRequested.next(E_VerificationRequestedResponse.SUCCESS);
      else this.onVerificationRequested.next(E_VerificationRequestedResponse.ERROR);
    });
  }

  public async verifyCode(action: Enums.Google_Recaptcha_Action, code: string): Promise<void> {
    const recaptcha_token = await this._generateRecaptchaToken(action);
    const query = `mutation {
      verifySmsVerificationCode(action: ${action}, recaptcha_token: "${recaptcha_token}", code: "${code}")
    }`;
    this._http.query(query).subscribe((response) => {
      if (response.errors) {
        this.onVerificationCheck.next(E_VerificationCheckResponse.ERROR);
        return;
      }
      if (response.data.verifySmsVerificationCode) this.onVerificationCheck.next(E_VerificationCheckResponse.SUCCESS);
      else this.onVerificationCheck.next(E_VerificationCheckResponse.INVALID);
    });
  }

  private async _generateRecaptchaToken(action: Enums.Google_Recaptcha_Action): Promise<string> {
    try {
      const token = await this._recaptchaService.getRecaptchaToken(action);
      return token;
    } catch {
      return "";
    }
  }
}
