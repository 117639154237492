import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import Bugsnag from "@bugsnag/js";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { navigateToUnsupported } from "../utils/unsupported";
import { BrandService } from "./brand.service";
import { JWTService } from "./jwt.service";
import { PatientsService } from "./patients.service";
import { BookableApptsService } from "./bookable-appts.service";
import { LocationService } from "./location.service";
import { lastValueFrom } from "rxjs";
import { CacheService } from "./cache.service";
import { environment } from "src/environments/environment";
import { NavigationService } from "./navigation.service";
import { PortalInPracticeService } from "./portal-in-practice.service";
import { CommonService } from "./common.service";
import { E_GlobalDomains } from "@shared/constants";
import { hideAppLoadingSpinner } from "src/app/utils";
import { SessionService } from "./session.service";

const STAGE_STORAGE_KEY = "stage";
const HOSTNAME_STORAGE_KEY = "hostname";
const PREVIOUS_SHORT_CODE_STORAGE_KEY = "previous_short_code";
const BUGSNAG_API_KEY = "577179af892ddc33c658dd488c901ca7";

@Injectable()
export class AppInitService {
  constructor(
    private _commonService: CommonService,
    private _brandService: BrandService,
    private _patientService: PatientsService,
    private _jwtService: JWTService,
    private _bookableApptsService: BookableApptsService,
    private _locationService: LocationService,
    private _cacheService: CacheService,
    private _http: HttpClient,
    private _navigationService: NavigationService,
    private _portalInPracticeService: PortalInPracticeService,
    private _sessionService: SessionService
  ) {}

  private _waitForBrandData(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if ((window as any).cachedBrandFetched) {
          clearInterval(interval);
          resolve();
        }
      }, 100);

      // If we don't get a response within 20 seconds, just resolve anyway so we can fallback
      setTimeout(() => {
        clearInterval(interval);
        resolve();
      }, 20000);
    });
  }

  private _setupBugsnag(): void {
    Bugsnag.start({
      apiKey: BUGSNAG_API_KEY,
      collectUserIp: false,
      appVersion: environment.VERSION,
      releaseStage: environment.STAGE ? environment.STAGE : "unset",
      onError: (event) => {
        console.warn("Bugsnag error", event);
      },
    });

    BugsnagPerformance.start(BUGSNAG_API_KEY);
  }

  // HACK: temporary name until we remove the dentr domains (can then be renamed to _handlePairDomain)
  private async _handlePairDomainOnNewPatientDomain(): Promise<void> {
    /*
      Check if we have previous PiP data (stage, site ID etc) and restore it
      If we had data which was restored, we need to init for paired because this device has already been paired
    */
    if (await this._portalInPracticeService.restorePipData()) {
      this._portalInPracticeService.initForPaired();
    } else if (!this._jwtService.isPip()) {
      // If we've not already paired, we need to init for pairing to allow the practice user to pair the device
      await this._portalInPracticeService.initForPairing();
    }

    // Hide the app loading spinner and setup Bugsnag before returning
    this._hideAppLoadingSpinner();
    this._setupBugsnag();

    return;
  }

  private async _handleRootDomain(): Promise<boolean> {
    const queryParams = this._locationService.search;
    const match = this._locationService.pathname.match(/^\/p\/([^\/]+)\/([^\/]+)$/);
    const practiceId = match ? match[1] : queryParams.practice_id;
    const patientId = match ? match[2] : queryParams.patient_id;

    if (!patientId || !practiceId) return false;

    const response = (await lastValueFrom(
      this._http.get(`${environment.GLOBAL_URL}/v1/patients/${patientId}`, {
        headers: {
          practice: practiceId,
        },
      })
    )) as { domain: string; stage: string; token: string };

    this._locationService.actualHostname = response.domain;
    this._locationService.hostname = response.domain;
    window.STAGE = response.stage;
    this._jwtService.setToken(response.token);
    this._cacheService.setSession(STAGE_STORAGE_KEY, response.stage);

    return true;
  }

  private async _handlePreferencesDomain(): Promise<boolean> {
    if (!this._locationService.isPreferencesDomain) return false;

    const path = "preferences/unsubscribe";
    const { pathname } = this._locationService;

    this._setupBugsnag();

    if (pathname === `/${path}`) return true;

    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());

    await this._determinePracticeStage(queryParams.practice_id);

    this._navigationService.navigate("preferences/unsubscribe", {
      state: queryParams,
      replaceUrl: true,
    });

    return true;
  }

  // eslint-disable-next-line complexity
  async init() {
    // Initialize the session service first to ensure we have the session cookie set
    await this._sessionService.init();

    // HACK: Temporary until we remove the dentr domains then this will become the default and old logic will be removed
    if (this._locationService.isNewPatientDomain) {
      // Handle the pair domain before anything else so that we can set the stage
      if (this._locationService.isPairDomain) {
        await this._handlePairDomainOnNewPatientDomain();

        return;
      }

      if (!this._locationService.isRootDomain || !(await this._handleRootDomain())) {
        if (await this._handlePreferencesDomain()) return;

        // We determine the stage from the backend Pusher message for PiP
        // Handle the new patient domain before updating the stage
        this._locationService.handleNewPatientDomain(environment.STAGE);

        // We need to determine the stage before starting Bugsnag
        await this._determineDomainStage();
      }
    }

    this._setupBugsnag();

    // See fetchBrandData in index.js for info regarding cachedBrandFallback and cachedBrandFetched
    await this._waitForBrandData();

    if (!window.localStorage || !window.sessionStorage) {
      this._hideAppLoadingSpinner();
      navigateToUnsupported();
      return;
    }

    if (this._locationService.isPairDomain) {
      await this._portalInPracticeService.initForPairing();

      this._hideAppLoadingSpinner();

      return;
    }

    if (this._jwtService.isPip()) {
      this._portalInPracticeService.initForPaired();
    }

    try {
      await this._handleGlobalDomains();
    } catch {
      this._redirectToLinkExpired();
      return;
    }

    const promises = new Array<Promise<any>>();
    promises.push(this._commonService.getDomainInfo());
    if (this._jwtService.isPatient()) promises.push(this._patientService.getPatient());

    await Promise.all(promises);

    // If we're not logged in as a patient we need to check the url for it's requested alias here
    if (!this._jwtService.isPatient()) {
      const { pathname } = this._locationService;
      if (pathname?.startsWith("/practices/")) {
        let alias = pathname.substring(11);
        if (alias.includes("/")) alias = alias.substring(0, alias.indexOf("/"));
        const aliasOk = await this._brandService.setRestrictedSiteIdFromAlias(alias);

        // If the alias isn't ok we should just return and then rely on brand-site-alias.component.ts to redirect us to the error page
        if (!aliasOk) return;
      }
    }

    if (this._shouldGetBookableAppts()) {
      await this._bookableApptsService.getBookableAppts();
    }

    this._hideAppLoadingSpinner();
  }

  private _shouldGetBookableAppts(): boolean {
    if (this._jwtService.isPatient()) {
      return !this._patientService?.patientInfo?.prevent_appointment_booking;
    }

    // If we're not going to support new patients at this brand and a patient isn't logged in yet, there's no point in checking bookable appointments
    const existingOnly = this._brandService.brand.existing_patients_only_on_brand_url && !this._brandService.restrictedSite;
    return !existingOnly;
  }

  private _hideAppLoadingSpinner(): void {
    hideAppLoadingSpinner();
  }

  private async _handleGlobalDomains(): Promise<void> {
    const { actualHostname, pathname } = this._locationService;
    const stageFromCache = this._cacheService.getSession(STAGE_STORAGE_KEY);
    const hostnameFromCache = this._cacheService.getSession(HOSTNAME_STORAGE_KEY);

    if (
      !actualHostname.match(/^(pay|sign|booking|pair|beta)\.(dentr|(sandbox\.)?portal\.dental)/) &&
      !Object.values(E_GlobalDomains).includes(actualHostname as E_GlobalDomains)
    )
      return;

    if (stageFromCache) window.STAGE = stageFromCache;
    if (hostnameFromCache) this._locationService.hostname = hostnameFromCache;

    if (this._locationService.isPairDomain) {
      return;
    }

    const match = pathname.match(/^\/r\/(.+)/);

    if (match) {
      await this._handleGlobalDomainShortCode(pathname, match[1]);
    } else if (!stageFromCache || !hostnameFromCache) {
      throw new Error("Not a short code link");
    }
  }

  private async _handleGlobalDomainShortCode(pathname: string, shortCode: string): Promise<void> {
    if (shortCode !== this._cacheService.getSession(PREVIOUS_SHORT_CODE_STORAGE_KEY)) {
      // #region Clear any existing session data to prevent the wrong details appearing
      this._cacheService.deleteSession(STAGE_STORAGE_KEY);
      this._cacheService.deleteSession(HOSTNAME_STORAGE_KEY);

      if (this._jwtService.isPatient()) {
        this._jwtService.delete();
      }
      // #endregion
    }

    const data = (await lastValueFrom(this._http.get(`${environment.GLOBAL_URL}/v1/short-codes/${shortCode}`))) as {
      stage: string;
      uri: string;
      portal_active: boolean;
    };

    if (data.portal_active && !this._locationService.isNewPatientDomain) {
      // Practice are paying for Portal so redirect to the brand URL
      this._locationService.href = `https://${data.uri}${pathname}`;
      return;
    }

    this._locationService.handleNewPatientDomain(environment.STAGE, data.uri);

    // Update stage and hostname used to determine which backend and domain to use for branding respectively
    window.STAGE = data.stage;

    this._cacheService.setSession(STAGE_STORAGE_KEY, data.stage);
    this._cacheService.setSession(HOSTNAME_STORAGE_KEY, this._locationService.hostname);
    this._cacheService.setSession(PREVIOUS_SHORT_CODE_STORAGE_KEY, shortCode);
  }

  private _redirectToLinkExpired(): void {
    this._hideAppLoadingSpinner();
    this._navigationService.navigate("/restricted/expired");
  }

  private _determineDomainStage(): Promise<void> {
    if (this._locationService.isRootDomain) {
      // No point looking up the stage for the root domain. This will be handled by the short code
      return Promise.resolve();
    }

    return this._determineStage(`domain=${this._locationService.actualHostname}`);
  }

  private _determinePracticeStage(practiceId: string): Promise<void> {
    return this._determineStage(`practice_id=${practiceId}`);
  }

  private async _determineStage(queryStringParameters: string): Promise<void> {
    try {
      const { GLOBAL_URL } = environment;
      const response = (await lastValueFrom(this._http.get(`${GLOBAL_URL}/v1/stages?${queryStringParameters}`))) as { stage: string };
      const { stage } = response;

      window.STAGE = stage;
    } catch {
      Bugsnag.notify(new Error(`Failed to determine domain stage for ${queryStringParameters}`));
      // Let the downstream logic handle it and go to the error-404 page
    }
  }
}
