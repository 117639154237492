import { Injectable } from "@angular/core";
import { getContrastColour } from "@shared/utils";
import * as colors from "tailwindcss/colors";
import * as culori from "culori";
import { hexToRgb } from "@shared/utils";
import * as palette from "../../../../palette";

type Palette = {
  name: string;
  colors: {
    [key: number]: string;
  };
};

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private _rgbToHex(r: number, g: number, b: number): string {
    const toHex = (c: number) => `0${c.toString(16)}`.slice(-2);
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  private _lighten(hex: string, intensity: number): string {
    const color = hexToRgb(`#${hex}`);

    if (!color) return "";

    const r = Math.round(color.r + (255 - color.r) * intensity);
    const g = Math.round(color.g + (255 - color.g) * intensity);
    const b = Math.round(color.b + (255 - color.b) * intensity);

    return this._rgbToHex(r, g, b);
  }

  private _darken(hex: string, intensity: number) {
    const color = hexToRgb(hex);

    if (!color) return "";

    const r = Math.round(color.r * intensity);
    const g = Math.round(color.g * intensity);
    const b = Math.round(color.b * intensity);

    return this._rgbToHex(r, g, b);
  }

  private _isLightColor(hexcolor: string): boolean {
    const labColor = culori.convertRgbToLab65(culori.parseHex(hexcolor));
    const labGray = culori.convertRgbToLab65(culori.parseHex(colors.gray[100]));
    const diff = culori.differenceCmc(1, 1)(labColor, labGray);

    return diff < 35;
  }

  private _setupPrimaryPalette(baseColor: string, colorName: string) {
    const response: Palette = {
      name: colorName,
      colors: {
        500: `#${baseColor}`.replace("##", "#"),
      },
    };

    const intensityMap: {
      [key: number]: number;
    } = {
      50: 0.95,
      100: 0.9,
      150: 0.85,
      200: 0.75,
      250: 0.7,
      300: 0.6,
      350: 0.5,
      400: 0.3,
      450: 0.2,
      600: 0.9,
      650: 0.85,
      700: 0.75,
      750: 0.7,
      800: 0.6,
      850: 0.5,
      900: 0.49,
    };

    [50, 100, 150, 200, 250, 300, 350, 400, 450].forEach((level) => {
      response.colors[level] = this._lighten(baseColor, intensityMap[level]);
    });

    [600, 650, 700, 750, 800, 850, 900].forEach((level) => {
      response.colors[level] = this._darken(baseColor, intensityMap[level]);
    });

    return response;
  }

  private _setHexColour = "";

  public setup(hexcolor: string) {
    if (hexcolor === this._setHexColour) return;

    this._setHexColour = hexcolor;

    const primaryPalette = this._setupPrimaryPalette(hexcolor, "primary-shade");
    const pinePalette = palette.pine;
    for (const key in primaryPalette.colors) {
      document.body.style.setProperty(`--${primaryPalette.name}-${key}`, primaryPalette.colors[key]);
    }
    for (const [key, value] of Object.entries(pinePalette)) {
      document.body.style.setProperty(`--pine-${key}`, String(value));
    }
    document.body.style.setProperty(`--primary`, hexcolor);
    document.body.style.setProperty(`--primaryContrast`, `${getContrastColour(hexcolor)}`);
    document.body.style.setProperty(`--primaryText`, this._isLightColor(hexcolor) ? colors.gray[700] : hexcolor);
  }
}
