import { Subject } from "rxjs";
import { AppointmentSettingsEntry } from "src/app/data_model/appointment-settings";
import { PracticeEntry } from "src/app/data_model/practice";

import { Injectable } from "@angular/core";

import { HttpService } from "./http.service";
import { CommonEntry } from "src/app/data_model/common";
import { GAService } from "./ga.service";
import { JWTService } from "./jwt.service";
import { PatientsService } from "./patients.service";
import { BrandService } from "./brand.service";
import { fromBrandInfoBase } from "src/app/data_model/brand-info";
import Bugsnag from "@bugsnag/js";
import { CacheService } from "./cache.service";
import { Constants } from "src/constants";
import { LocationService } from "./location.service";
import { FeatureFlagsService } from "./feature-flags.service";
import { BrandInfoSiteBase } from "@backend/graph/brand_info/brand-info-base";
import { environment } from "src/environments/environment";
import { NavigationService } from "./navigation.service";
import { E_ManageFeatures } from "@backend/common/enums/feature-flags.enum";
import { AcquisitionSourceEntry } from "src/app/data_model/acquisition-source";

const COMMON_QUERY = (uri: string) => `{
  practice {
    currency_code
    dial_code
    iso_country_code
    time_zone
    phone_number
    is_multisite
    name
    show_acquisition_source
    show_emergency_contact
    show_patient_gp_details
    acquisition_sources {
      items {
        id
        name
        show_in_portal
      }
    }
    appointment_settings {
      practice_id
      allow_appointment_cancellations
      show_all_slots_new_patients
      required_to_select_site
      show_all_slots_existing_patients
      min_wait_time
      max_wait_time
      max_days_ahead_bookings
      can_book_together
      hygiene_lapsing_exam_types
    }
  }
  brand_info(uri: "${uri}") {
    id
    appointment_list_first
    existing_patients_only_on_brand_url
    ga_tracking_cookie_domain
    ga_tracking_id
    ga_tracking_link_domain
    site_locking_enabled
    sites {
      site_id
      site_name
      site_phone_number
      site_email_address
      site_website
      site_address_line_1
      site_address_line_2
      site_address_line_3
      site_town
      site_county
      site_postcode
      stripe_account_id
      settings {
        forms__nhs_pr
        forms__medical_history
        forms__treamtent_plan_estimates
        payments__balance_payments
        payments__invoice_payments
      }
    }
  }
}
`;

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private _dataLoaded = false;
  private _commonData: CommonEntry;
  public onInitData: Subject<CommonEntry> = new Subject();
  private _isFetching: boolean;
  constructor(
    private _brandService: BrandService,
    private _httpService: HttpService,
    private _patientsService: PatientsService,
    private _jwtService: JWTService,
    private _gaService: GAService,
    private _cacheService: CacheService,
    private _locationService: LocationService,
    private _featureFlagsService: FeatureFlagsService,
    private _navigationService: NavigationService
  ) {}

  public get isCommonDataLoaded(): boolean {
    return this._dataLoaded;
  }

  public get isPostcodeOptional(): boolean {
    return this.practice.iso_country_code.includes("IE");
  }

  public get practice(): PracticeEntry {
    return this._commonData.practice;
  }

  public get max_days_ahead_bookings(): number {
    return this._commonData.practice.appointment_settings.max_days_ahead_bookings;
  }

  public get urlSiteId(): string {
    return this._brandService.restrictedSiteId;
  }

  public getSitePhoneNumber(siteId: string): string | null {
    const site = this._commonData.brand_info.sites.find((s) => s.site_id === siteId);
    if (site) return site.site_phone_number;
    return null;
  }

  public signout() {
    this._cacheService.deleteSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY);
    Bugsnag.leaveBreadcrumb("Sign out");
    this._gaService.action("signout");
    window.sessionStorage.clear();
    this._jwtService.signout();
    window.location.href = "/signout";
  }

  public getSite(site_id: string): BrandInfoSiteBase | undefined {
    return this._commonData.brand_info.sites.find((site) => site.site_id === site_id);
  }

  public getDomainInfo(): Promise<void> {
    const { hostname, pathname } = this._locationService;
    // See fetchBrandData in index.js for info regarding cachedBrandFallback and cachedBrandFetched
    const fallback = (window as any).cachedBrandFallback && (window as any).cachedBrandFetched;
    let url = `${environment.REST_URL}/api/domains/info?uri=${hostname}`;
    if (fallback) url += "&fallback=true";

    return new Promise<void>((resolve, reject) => {
      this._httpService.send(url, { method: "GET" }).subscribe(
        (graphData: any) => {
          switch (graphData.code) {
            case 200:
              delete graphData.code;
              delete graphData.redirect_uri;
              const forceSwap = pathname === "/login/redirect";
              const { feature_flags, manage_features } = graphData;

              this._jwtService.setPublicToken(graphData.public_jwt, forceSwap);

              // If there is no cached S3 brand data, then set the brand info from the domains call
              if (fallback) {
                this._cacheService.set(Constants.BRAND_INFO_STORAGE_KEY, JSON.stringify(graphData));
              } else {
                const cachedBrandData = this._cacheService.get(Constants.BRAND_INFO_STORAGE_KEY);
                if (cachedBrandData) graphData = JSON.parse(cachedBrandData);
              }

              // If the manage feature is off, then override the favicon url to null
              if (!manage_features || !manage_features[E_ManageFeatures.CUSTOM_FAVICON]) graphData.favicon_preview_url = null;

              this._featureFlagsService.setFeatureFlags(feature_flags);
              this._brandService.setupBrandingConfig(graphData);

              break;
            case 302:
              window.open(`https://${graphData.redirect_uri}/${(pathname || "").replace(/^\//, "")}`, "_self");
              return;

            default:
              Bugsnag.leaveBreadcrumb("Unknown error code getting brand info", { code: "e15f44b0", httpCode: graphData.code });
              Bugsnag.notify("activating error 404 route");
              this._navigationService.navigate("error-404");
              return;
          }
          resolve();
        },
        (err) => {
          Bugsnag.leaveBreadcrumb("Error getting brand info", { code: "28ba0137", err });
          Bugsnag.notify("activating error 404 route");
          this._navigationService.navigate("error-404");
          reject(err);
        }
      );
    });
  }

  public getCommonData(force = false): Promise<any> {
    const jwt = this._jwtService.getJWT();

    return new Promise((resolve, reject) => {
      // If we are already fetching, then wait until the fetch is complete and resolve the data
      if (this._isFetching) {
        const interval = setInterval(() => {
          if (!this._isFetching) {
            clearInterval(interval);
            resolve(this._commonData);
          }
        }, 300);
        return;
      }

      if (!force && this._dataLoaded) {
        this.onInitData.next(this._commonData);
        this._dataLoaded = true;
        resolve(this._commonData);
        return;
      }
      if (jwt) {
        this._isFetching = true;
        this._httpService.query<any>(COMMON_QUERY(this._locationService.hostname)).subscribe(
          (response: any) => {
            const common_entry = new CommonEntry();
            const { practice } = response.data;

            // Setup entries
            const practice_entry = new PracticeEntry(practice);
            const appointment_settings_entry = new AppointmentSettingsEntry(practice.appointment_settings);
            const acquisition_sources = new Array<AcquisitionSourceEntry>();

            if (practice.acquisition_sources.items)
              for (const source of practice.acquisition_sources.items) acquisition_sources.push(new AcquisitionSourceEntry(source));

            // Setup practice data
            if (!practice_entry.iso_country_code) practice_entry.iso_country_code = "GB";
            if (!practice_entry.time_zone) practice_entry.time_zone = "Europe/London";
            if (!practice_entry.currency_code) practice_entry.currency_code = "GBP";
            if (!practice_entry.dial_code) practice_entry.dial_code = "44";
            practice_entry.appointment_settings = appointment_settings_entry;
            practice_entry.acquisition_sources.items = acquisition_sources;

            // Setup common data
            this._commonData = common_entry;
            this._commonData.practice = practice_entry;
            this._commonData.brand_info = response.data.brand_info;
            this._gaService.setup(response.data.brand_info);
            this._brandService.brand = fromBrandInfoBase(response.data.brand_info, this._patientsService.patientInfo);
            if (response.errors) reject(response);

            this.onInitData.next(this._commonData);
            this._dataLoaded = true;
            this._isFetching = false;

            this._featureFlagsService.forceBasketlessBooking = !this._commonData.brand_info.sites.some((site) => !site.stripe_account_id);

            resolve(this._commonData);
          },
          (err) => {
            console.error("error querying common data", err);
            reject(err);
          }
        );
      } else {
        this.onInitData.error("No JWT");
      }
    }).catch((err) => {
      this._isFetching = false;
      console.error("Error getting common data", err);
      throw err;
    });
  }
}
